const features = [
   
    /*users:*/ { items: ["Users", "Maximum number of users in your workspace", "1", "1", "3", "5", "Custom", "Custom"]},
    /*additionalUser:*/ { items: ["Additional Users", "Price per user, per month", "-", "$9", "$9", "$9", "Custom", "Custom"]},
    /*flows:*/ { items: ["Flows", "Flows are the automation projects. Flow limits per workspace", "3", "50", "250", "Unlimited", "Custom", "Custom"]}, 
    /*versions:*/ { items: ["Versions", "For hosted plans, versioning is only provided with paid plans.", "-", "✓", "✓", "✓", "✓", "✓"]}, 
    /*scheduling:*/ { items: ["Scheduling", "Scheduling, is only provided on production robots.", "-", "-", "> 5 min", "> 1 min", "> 1 min", "✓"]}, 
    /*queues:*/ { items: ["Queues", "Queues hold items to be processed.", "-", "-", "-", "1 GB / per prod robot", "Custom", "✓"]},
    /*queue retntion:*/ { items: ["Queue Retention", "Queue items either processed or not are deleted after this period.", "-", "-", "-", "7 days", "Custom", "Custom"]},
    /*audit:*/ { items: ["Audit Logs", "The centralized audit log system of user and workspace activities", "-", "1 Month", "3 Months", "6 Months", "Custom", "Custom"]},
    /*job:*/ { items: ["Job Logs", "Scheduled and triggered flow status logs", "-", "1 Month", "3 Months", "6 Months", "Custom", "Custom"]},
    /*triggers:*/ { items: ["Triggers", "Robots stay idle while polling or waiting for a trigger: File System, Http, e-Mail, Hardware, Schedule, Queue. You can set unlimited triggers on a Robot.", "-", "-", "-", "✓", "✓", "✓"]},
    /*standart packages:*/ { items: ["Standard Packages", "Standard packages from stable repository are available.", "✓", "✓", "✓", "✓", "✓"]},
    /*custom packages:*/ { items: ["Custom Packages", "You can create custom packages and deploy to your custom repository.", "-", "✓", "✓", "✓", "✓", "✓"]},
    /*forms:*/ { items: ["Forms", "Create publicly shareable Robomotion Forms to trigger flows on submit.", "-", "-", "-", "✓", "✓", "✓"]},
    /*developmentRobots: */{ items: ["Development Robots", "Used for development. Every workspace comes with 1 free Development Robot. Flow Designer runs flows on Development Robots which can not be scheduled and must be installed on a local or remote machine.", "1", "1", "3", "5", "Custom", "Custom"]}, 
    /*additionalDevelRobot:*/ { items: ["Additional Development Robots","Price per robot, per month", "-", "$49", "$49", "$49", "Custom"]}, 
    /*applicationRobots: */{ items: ["Application Robots", "Used for bots. Every bot in Bot Console requires an Application Robot. Must be installed on a local or remote machine.", "1", "3", "5", "15", "Custom", "Custom"]}, 
    /*additionalApplicationRobots:*/ { items: ["Additional Application Robots","Price per robot, per month", "-", "$19", "$19", "$19", "Custom"]}, 
    /*productionRobots:*/ { items: ["Production Robots", "Used for production. Flows can be scheduled on Production Robots from Admin Console or Production Robots can watch Triggers. Must be installed on a local or remote machine.", "-", "-", "1", "2", "Custom", "Custom"]}, 
    /*additionalProdRobot:*/ { items: ["Additional Production Robots", "Price per robot, per month", "-", "$99", "$99", "$99", "$99", "Custom"]},
    /*supportChannel:*/ { items: ["Support Channel", "Support is available during 09:00-17:00 UTC+3", "Community", "Email", "Email", "Email + Chat", "Email + Chat + Phone", "Email-Phone"]},

];

export default features;

import {
    createStyles,
    Text,
    Container,
    Card,
    Center,
    Image,
} from '@mantine/core';
import remarkGfm from 'remark-gfm';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import './Policy.css';
import colors from '../../const/colors';


const HeadingRenderer = (props) => <h2 id={("" + (props.children)).replace(/\s+/g, '-').toLowerCase()}>{props.children}</h2>
const ParagraphExtra = (props) => <Text size="md" mt={4}>{props.children}</Text>
//const ImageExtra = (props) => <img src={props.src} style={{ maxWidth: "90%", textAlign: "center", margin:"auto"}}  />
const ImageWithcard = (props) => 
<Card shadow="xs" m={20} p={30} radius="md" >
    <Card p={0} m={0} radius="lg" >
        <Image fit="contain" src={props.src}></Image>
    </Card>
</Card>


const useStyles = createStyles((theme) => ({
    wrapper: {
        position: 'relative',
        paddingBottom: 100,
        paddingTop:72,

        '@media (max-width: 755px)': {
            paddingTop: 80,
        },
    },

    hero: {
        position: 'relative',
        zIndex: 1,
    },

    title: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "4.5em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    subTitle: {
        textAlign: 'center',
        fontWeight: 300,
        fontSize: "2em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    description: {
        color: colors.Primary,
        textAlign: 'center',
        fontSize: 30,
        maxWidth: "800px",
        fontFamily: 'Barlow',
        
    },

    img: {
        maxWidth: 650,
    },


    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

    

}));

const Body = ({content}) => {
    const { classes } = useStyles();
    return (
        <Center>
            <Container id="policy" className={classes.wrapper} size={800}>
                <ReactMarkdown children={content} remarkPlugins={[remarkGfm]} components={{ h2: HeadingRenderer, p: ParagraphExtra, img: ImageWithcard }} />
            </Container>
        </Center>
    );
}

export default Body;

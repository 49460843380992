import { Center, Container, Text, Title, createStyles, useMantineTheme } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import data from './data.jsx'
import colors from '../../../const/colors.jsx';
import { useMediaQuery } from '@mantine/hooks';
import FeatureView from './FeatureView'

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin:"auto",
        paddingTop: 0,
        paddingBottom: 50,
    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingTop: 100,
        paddingBottom: 60,
    },

    title: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: "3em",
        color: colors.PrimaryText,
        fontFamily: 'Inter',

        [theme.fn.smallerThan('lg')]: {
            fontSize: "3em",
        },
    },

    topTitle: {
        textAlign: 'center',
        fontWeight: 400,
        fontSize: "20px",
        fontFamily: 'Inter',
    },

    description: {
        color: colors.Primary,
        textAlign: 'center',
        fontSize: 20,
        maxWidth: "850px",
    },

    highlight: {
        fontWeight: 800,

    },
    underlined: {
        textDecoration: "underline wavy #2196F3"
    },

    subDescription: {
        textAlign: 'center',
        fontSize: 16,
        marginTop: 100,

    },


    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

}));

const Features = () => {

    const { classes } = useStyles();

    const features = data.map((feature) => (
        FeatureView(feature) 
    ));

    return (
        <>
        <Container className={classes.wrapper}>
            <div className={classes.hero}>
                <Center>
                    <Title className={classes.title}>
                        From Robomotion  <Text component="span"
                                    className={classes.highlight}
                                    inherit
                                    variant="gradient"
                                    gradient={{ from: colors.Primary, to: colors.Primary }}
                                >App Store</Text>
                    </Title>
                </Center>

                    <Container pt={48}>
                        <Center>
                            <Text className={classes.description}>
                            We introduce the initial suite of applications developed with the Robomotion Platform, targeting the key marketing functions including <b><u>Lead Generation</u></b>, <b><u>Video Content</u></b>, and <b><u>SEO Writing</u></b>.
                            </Text>
                        </Center>
                        <Center p={32}>
                            <Text className={classes.description}>
                            And this is only the beginning, with exciting additions such as Clip Generator and Email Cold Outreach apps on the way. All these tools are part of your subscription plan, offering extensive support for your marketing efforts.
                            </Text>
                        </Center>

                    </Container>
            </div>
        </Container>
        {features}
        </>
    );
}

export default Features;

import {
    createStyles,
    Title,
    Text,
    Container,
    Image,
    Center,
} from '@mantine/core';
import colors from '../../const/colors.jsx';

const useStyles = createStyles((theme) => ({
    wrapper: {
        position: 'relative',
        margin: "auto",
        display: 'flex',
        paddingTop: 80,

        '@media (max-width: 755px)': {
            paddingTop: 80,
        },
    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 80,
    },

    title: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "3.5em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    subTitle: {
        textAlign: 'center',
        fontWeight: 300,
        fontSize: "2em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    description: {
        color: colors.Primary,
        textAlign: 'center',
        fontSize: 24,
        maxWidth: "800px",
        fontFamily: 'Barlow',
        
    },

    subDescription: {
        marginTop: "80px",
        textAlign: 'center',
        fontSize: 16,
    },


    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

    img: {
        maxWidth: 650,
    },

}));

const TeamHero = () => {

    const { classes } = useStyles();

    return (
        <Center>
            <Container className={classes.wrapper} size={1200}>
                <div className={classes.hero}>
                    <Title className={classes.title}>
                         About <b>Robomotion</b>
                    </Title>

                    <Title className={classes.subTitle}>
                        We help companies to automate their manual processes with RPA
                    </Title>
                    
                    <Container pt={40} size={400}>
                        <Center>
                            <Image fit="contain" className={classes.img} src={"/images/about/robots-working.png"} alt="Robomotion Team"></Image>
                        </Center>
                    </Container>
                    <Container p={0} size={1100}>
                        <Center>
                            <Text size="lg" className={classes.description}>
                            Robomotion offers a comprehensive and easy-to-use RPA platform that helps companies automate their business processes quickly and efficiently. We provide software robots that can accurately carry out repeatable tasks, freeing up employees to focus on more value-added activities. In addition, our platform is backed by a team of experienced RPA experts who can help you implement and optimize your automation solution. Contact us today to learn more about how we can help you increase productivity and profitability with Robotic Process Automation.
                            </Text>
                        </Center>
                    </Container>

                </div>
            </Container>
        </Center>
    );
}

export default TeamHero;

import Hero_Dev from './developers/Hero';
import Hero_Users from './users/Hero';
import Apps_Users from './users/Apps';
import AppsExtra_Users from './users/AppsExtra';
import UserBase_Dev from './developers/UserBase';
import UserBase_Users from './users/UserBase';
import Users from '../shared/Users';
import Discord from '../shared/Discord';
import Features_Dev from './developers/Features';
import Features_Users from './users/Features';
import React, {useState} from 'react';
import colors from '../../const/colors';

import {
    Container,
    Center,
    Text,
    Button,
    createStyles,
    SegmentedControl,
} from '@mantine/core';


const useStyles = createStyles((theme) => ({
    
    subDescription: {
        marginTop: "10px",
        textAlign: 'center',
        fontSize: 16,
    },


}));

const Home = () => {
    const { classes } = useStyles();

    const [value = 'For Developers', setValue] = useState();

    return (
        <>
            <Hero_Dev />
            <UserBase_Dev />
            <Features_Dev />
            <Hero_Users />
            <UserBase_Users />
            <Apps_Users />
            <AppsExtra_Users />
            <Users />
            <Discord />

        </>
    );
}

export default Home;
import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from "react-router-dom";
import frontmatter from 'frontmatter';
import Hero from './Hero';
import Users from '../../shared/Users';
import Discord from '../../shared/Discord';
import Body from './Body';
import Cases from './Cases';
import CTA from './CTA';

const UseCases = () => {
    const { slug } = useParams();
    const [post, setPost] = useState('');

    useEffect(() => {
        import(`./data/${slug}.md`).then(res => {
            fetch(res.default)
            .then(res => res.text())
            .then(res => setPost(res));
        })
        .catch(err => console.log(err));
    });

    const mrkdown = frontmatter(post);
    const title = mrkdown.data ? mrkdown.data.title : "Robomotion RPA";

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta name="twitter:title" content={title} />
            </Helmet>
            <Hero data={mrkdown.data} />
            <Body data={mrkdown.data} />
            <Cases data={mrkdown.data} />
            <CTA data={mrkdown.data} />
            <Users />
            <Discord />
        </>
    );
}

export default UseCases;
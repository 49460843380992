import { Helmet } from 'react-helmet';
import Hero from './Hero';
import Users from '../../shared/Users';
import Discord from '../../shared/Discord';
import Partners from './Partners';
import PartnerTypes from './PartnerTypes';

const Enterprise = () => {
    const title = "Enterprise Partners - Robomotion RPA"
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta name="twitter:title" content={title} />
            </Helmet>                 
            <Hero />
            <PartnerTypes />
            <Partners />
            <Users />
            <Discord />
        </>
    );
}

export default Enterprise;
const data = [
    {
        topTitle: "Multi-Branching",
        title: {
            highlight: "Concurrency",
            normal: "at Scale",
        },
        description: "Robomotion robots can execute multiple automation sequences simultaneously.",
        bottomTitle: "Key Features",
        list: [
            { start: "Multi-Branching", rest: "Our most powerful feature allows you to execute your automation steps in parallel branches simultaneously." },
            { start: "Utilization", rest: "Your robot will leverage the full power of your host environment's resources." },
            { start: "Achieve", rest: "RPA tasks complete 10x faster while utilizing the same hardware." },
        ],
        image: '/images/home/multi_branching_ss.gif',
        header: '/images/home/Concurrency.png',
        placement: "right",
    },
    {
        topTitle: "Interaction",
        title: {
            highlight: "Engage",
            normal: "with your Robots",
        },
        description: " Robomotion provides interfaces, enabling direct interaction with your robots.",
        bottomTitle: "Key Features",
        list: [
            { start: "Robomotion Assistant", rest: "Convert any automation into an interactive chatbot." },
            { start: "Robomotion Forms", rest: "Lets you collect data and trigger your automations from web." },
            { start: "ChatGPT", rest: "Integration to enhance your chatbot capabilities and deliver unparalleled conversational experiences." },
        ],
        
        image: '/images/home/talk_to_your_robots_ss.gif',
        header: '/images/home/Engage.png',
        placement: "right",
    },
    {
        topTitle: "Runs on Your System",
        title: {
            highlight: "Local",
            normal: "Run",
        },
        description: "Execute your automations on your personal computer or VPS to ensure data security.",
        bottomTitle: "Key Features",
        list: [
            { start: "Control", rest: "Lets you run your robots on your own computer or VPS with full control over the environment." },
            { start: "Cross-Platform", rest: "Robots can run on Linux, Mac, Windows or even Raspberry Pi." },
            { start: "Data", rest: "Data is processed within your own environment, ensuring complete privacy, security, and control over your data." },

        ],
        image: '/images/home/desktop_run_ss.gif',
        header: '/images/home/Concurrency.png',
        placement: "right",
    },
    {
        topTitle: "Runs on Our System",
        title: {
            highlight: "Cloud",
            normal: "Run",
        },
        description: "Run your robots directly on our cloud, without any installations and maintenance.",
        bottomTitle: "Key Features",
        list: [
            { start: "Ease", rest: "Allows you to run your robots in the cloud, offering flexibility and ease of use suitable for businesses of all sizes."},
            { start: "Pay-per-Use", rest: "Allows you to only pay for the cloud minutes you use, optimizing costs and resource allocation." },
            { start: "Effortless Scaling", rest: "Achieved through on-demand running, allowing for efficient resource utilization and cost savings." },
        ],
        image: '/images/home/cloud_run_ss.gif',
        header: '/images/home/Concurrency.png',
        placement: "right",
    },    
    {
        topTitle: "Intuitive",
        title: {
            highlight: "Flow",
            normal: "Designer",
        },
        description: "Robomotion introduces an intuitive drag & drop automation builder, the Flow Designer.",
        bottomTitle: "Key Features",
        list: [
            { start: "Drag & Drop", rest: "The interface ensures a seamless experience, allowing users to design flows with ease and intuitively navigate through options." },
            { start: "Open Canvas", rest: "Provides a flexible space to freely design and arrange your automation sequences." },
            { start: "User-Friendly", rest: "Platform ensuring smooth onboarding and fast adoption of the tool." },
        ],
        image: '/images/home/flow_designer_ss.gif',
        header: '/images/home/Concurrency.png',
        placement: "right",
    },
    {
        topTitle: "Orchestrator",
        title: {
            highlight: "Admin",
            normal: "Console",
        },
        description: "Robomotion Admin Console is for orchestrating your automation robots. ",
        bottomTitle: "Key Features",
        list: [
            { start: "Scheduling", rest: "To set up and customize automations according to specific timeframes and intervals." },
            { start: "Trigger", rest: "Configuration and management to initiate automations based on specific events or conditions." },
            { start: "Queue", rest: "Management for optimizing the execution of automation tasks and workload distribution." },
        ],
        image: '/images/home/admin_console_ss.gif',
        header: '/images/home/Concurrency.png',
        placement: "right",
    },
    {
        topTitle: "App Launchpad",
        title: {
            highlight: "Apps",
            normal: "of Robomotion",
        },
        description: "Robomotion Apps simplifies the process of launching pre-built automations.",
        bottomTitle: "Key Features",
        list: [
            { start: "Intuitive", rest: "User-friendly interface designed specifically for users without technical expertise." },
            { start: "Automation Launch", rest: "For easy initiation of automation tasks and interactions with the robots." },
            { start: "File Storage", rest: "To securely store the generated result files from automation processes." },
        ],
        image: '/images/home/bot_console_ss.gif',
        header: '/images/home/Concurrency.png',
        placement: "right",
    }
];

export default data;

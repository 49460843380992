import data from "../webinars/data"
import { Helmet } from 'react-helmet';

import {
    createStyles,
    Container,
    Center,
    SimpleGrid,
    Title,
    Group,
    Text,
    Button,
    Card,
} from '@mantine/core';
import WebinarForm from "./WebinarForm";

import colors from '../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        position: 'relative',
        margin: "auto",
        display: 'flex',
        paddingBottom: 80,
        paddingTop: 100,

    },

    subFrame: {
        position: 'relative',
        margin: "auto",
        display: 'flex',
        paddingBottom: 50,
    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 80,
    },

    title: {
        textAlign: 'left',
        fontWeight: 700,
        fontSize: "3em",
        lineHeight: "1.2",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
    },

    date: {
        textAlign: 'left',
        fontWeight: 700,
        fontSize: "1.6em",
        lineHeight: "1.2",
        letterSpacing: "-0.02em",
        color: colors.Accent1,
    },

    subTitle: {
        color: colors.PrimaryText,
        textAlign: 'left',
        letterSpacing: "-0.02em",
        lineHeight: "1.4",
        fontSize: 32,
        fontWeight: 800,
    },

    description: {
        color: colors.PrimaryText,
        textAlign: 'left',
        fontSize: 18,
        marginTop: 10,
        marginBottom: 10,
    },

    subDescription: {
        textAlign: 'center',
        fontSize: 16,
        marginTop: 10,
    },


    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },
}));


function findWebinar(slug) {
    for (var i = 0; i < data.length; i++) {
        if (slug === data[i].slug) {
            return data[i];
        }
    }
    return null;
}


const Webinar = ({ slug }) => {
    const { classes } = useStyles();
    const webinar = findWebinar(slug);

    const googleLink = `http://www.google.com/calendar/event?action=TEMPLATE&dates=${webinar.dates}&text=${webinar.title}&location=${webinar.link}&details=${webinar.text}`;
    
    const title = webinar.title ? webinar.title : "Webinars - Robomotion RPA";
    const image = webinar.image ? window.location.origin + webinar.image + "?auto=compress,format&rect=0,0,1200,720&w=1200&h=720" : "";

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta name="twitter:title" content={title} />
                <meta property="og:image" content={image} />
                <meta name="twitter:image" content={image} />
            </Helmet>  
            <Container className={classes.wrapper} size={1200} >
                <div>
                    <Title className={classes.subTitle}>

                        <Text component="span"
                            className={classes.highlight}
                            inherit
                            variant="gradient"
                            gradient={{ from: colors.Secondary, to: colors.Secondary }}
                        >
                            Live Webinars </Text>{''}

                    </Title>

                    <Title className={classes.title}>
                        {webinar.title}
                    </Title>

                    <Container p={0} size={"100%"}>
                        <Text className={classes.description}>
                            View the recording of our webinars and discover how  <u><b>Robotic Process Automation,</b></u> can automate <u><b>%80</b></u> of your mundane and repetitive work.
                        </Text>
                    </Container>

                    <Group position="apart" pb={10}>
                        <Text size="md" className={classes.date}>
                            <span>{webinar.date}</span>{' '}<span>{webinar.time}</span>
                        </Text>
                        <Text size="lg" color="dimmed" >
                            <Button size="md" radius="md" onClick={() => { window.location.href = googleLink }} variant="gradient" gradient={{ from: colors.Primary, to: colors.Primary }}>
                                + ADD TO CALENDAR
                            </Button>
                        </Text>
                    </Group>
                </div>
            </Container>

            <Container className={classes.subFrame} size={1200}>
                <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }, { maxWidth: 'lg', cols: 2 }]}>
                    <div className={classes.hero}>
                        <Card shadow="xs" radius="md" className={classes.imageCard}>
                            <iframe  width="100%" height="360" src={`https://www.youtube.com/embed/${webinar.youtube}`} title={webinar.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
                        </Card>
                    </div>
                    <Center>
                        {<WebinarForm />}
                    </Center>
                </SimpleGrid>
            </Container>
        </>


    );
}

export default Webinar;

import {
    createStyles,
    Title,
    Container,
    SimpleGrid,
    Paper,
    Image,
    Center,
} from '@mantine/core';
import colors from '../../const/colors.jsx';

const useStyles = createStyles((theme) => ({
    wrapper: {
        position: 'relative',
        margin: "auto",
        display: 'flex',
        paddingTop: 80,

        '@media (max-width: 755px)': {
            paddingTop: 80,
        },
    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 80,
    },

    title: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "3.5em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    subTitle: {
        textAlign: 'center',
        fontWeight: 300,
        fontSize: "2em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    description: {
        color: colors.Primary,
        textAlign: 'center',
        fontSize: 24,
        maxWidth: "800px",
        fontFamily: 'Barlow',
        
    },

    subDescription: {
        marginTop: "80px",
        textAlign: 'center',
        fontSize: 16,
    },


    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

    img: {
        maxWidth: 650,
    },

}));

const TeamInvestors = () => {

    const { classes } = useStyles();

    return (
        <Center>
            <Container className={classes.wrapper} size={"%100"} pb={100} m={0} mb={-3}>
                <div className={classes.hero}>
                    <Title className={classes.title}>
                    Our investors and advisors
                    </Title>

                    <Container px={30} size={1100}>
                        <Center>
                            <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'sm', cols: 1 }, { maxWidth: 'md', cols: 2 }]}>
                            <Paper
                                p="lg"
                                sx={(theme) => ({
                                    backgroundColor: colors.Transparent,
                                })}
                                >
                                <Image fit="contain" src={"/images/about/lima.png"} height={60} radius={0} mx="auto" href="https://limaventures.com"/>
                            </Paper>
                            <Paper
                              p="lg"
                              sx={(theme) => ({
                                  backgroundColor: colors.Transparent,
                              })}
                                >
                                <Image fit="contain" src={"/images/about/hivc.png"} width={230} radius={0} mx="auto" href="https://hivc.io/en/"/>
                            </Paper>
                            <Paper
                                p="lg"
                                sx={(theme) => ({
                                    backgroundColor: colors.Transparent,
                                })}
                                >
                                <Image fit="contain" src={"/images/about/ea-force.png"} height={55}  radius={0} mx="auto" href="https://www.eaforce.com"/>
                            </Paper>
                            </SimpleGrid>
                        </Center>
                    </Container>
                </div>
            </Container>
        </Center>
    );
}

export default TeamInvestors;

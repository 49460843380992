
import {
    createStyles,
    Title,
    Text,
    Card,
    Group,
    Input,
    Button,
    Center
} from '@mantine/core';

import colors from '../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        position: 'relative',

        '@media (max-width: 755px)': {
            paddingTop: 80,
        },
    },

    hero: {
        zIndex: 1,
        paddingLeft: 40,
    },

    title: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: 32,
        lineHeight: "1.2",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        marginBottom: theme.spacing.xl,
        paddingTop: theme.spacing.xl,

        [theme.fn.smallerThan('lg')]: {
            fontSize: 24,
        },
    },

    img: {
        maxWidth: 650,
    },
    subTitle: {
        color: colors.PrimaryText,
        textAlign: "center",
        letterSpacing: "-0.02em",
        lineHeight: "1.4",
        fontSize: 26,
        marginBottom: 25,
    },
    description: {
        color: colors.PrimaryText,
        textAlign: "center",
        letterSpacing: "-0.02em",
        lineHeight: "1.4",
        fontSize: 22,
        marginBottom: 15,
    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

    input: {
        width: "100% !important",
        margin: "auto",
    },

    imageCard: {
        backgroundColor: colors.TertiaryBackground,
    },

    imageCardChild: {
        backgroundColor: colors.WhiteText,
    },

    card: {
        maxWidth: "500px",
        backgroundColor: colors.TertiaryBackground,

    },

}));

const WebinarForm = () => {
    const { classes } = useStyles();

    return (
            <Center>
                    <Card shadow="md" p={20} mx={20} radius="lg" className={classes.imageCardChild}>
                        <Title className={classes.title}>
                            Register to {' '}
                            <Text component="span"
                                className={classes.highlight}
                                inherit
                                variant="gradient"
                                gradient={{ from: colors.Primary, to: colors.Primary }}
                            >
                                Webinar&nbsp;
                            </Text>{''}
                        </Title>

                        <form method="POST" action="https://eu.customerioforms.com/forms/submit_action?site_id=12bcd670fb19807485db&form_id=01fwk6afmbeajzcbqzjtmedpas&success_url=/forms/webinar-thankyou">
                            <Group mt="lg" size={"%100"}>
                                <Input.Wrapper label="Name" required className={classes.input} >
                                    <Input id="name" name="name" placeholder="Your Name:" />
                                </Input.Wrapper>
                            </Group>

                            <Group mt="lg">
                                <Input.Wrapper label="Surname" required className={classes.input} >
                                    <Input id="surname" name="surname" placeholder="Your Surname:" />
                                </Input.Wrapper>
                            </Group>

                            <Group mt="lg">
                                <Input.Wrapper label="Email" required className={classes.input} >
                                    <Input id="email" name="email" placeholder="Your Email:" />
                                </Input.Wrapper>
                            </Group>

                            <Group mt="lg">
                                <Input.Wrapper label="Company" required className={classes.input} >
                                    <Input id="company" name="company" placeholder="Your Company:" />
                                </Input.Wrapper>
                            </Group>

                            <Group mt="lg">
                                <Input.Wrapper label="Country" required className={classes.input} >
                                    <Input id="country" name="country" placeholder="Your Country:" />
                                </Input.Wrapper>
                            </Group>

                            <Card.Section className={classes.footer} height="100">
                                <Group position="apart" height="100">
                                    <Text size="sm" color="dimmed" height="100" p={30}>
                                        We need to store and process your personal data to provide you with the requested content. By continuing, you agree that we store your personal data for this purpose. Read the <a href="/legal/privacy"><u>Privacy Policy</u></a> for more information.
                                    </Text>
                                </Group>
                            </Card.Section>
                            <Group position="right" mt="md">
                                <Button type="submit"  variant="gradient" gradient={{ from: colors.Primary, to: colors.Primary }}>Register</Button>
                            </Group>
                        </form>
                    </Card>
            </Center>
    );

}

export default WebinarForm;
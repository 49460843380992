const robots = {
    "linux": {
        os: "Linux",
        icon: "/images/os/linux-icon.png",
        version: "v24.7.0",
        arch: "64 bit",
        release: "Jul 02, 2024",
        downloads: [
            {
                "name": "Ubuntu 22.04",
                "link": "https://packages.robomotion.io/releases/ubuntu/ubuntu-22/robomotion_24.7.0_amd64.deb"
            },
            {
                "name": "Ubuntu 20.04",
                "link": "https://packages.robomotion.io/releases/ubuntu/robomotion_24.7.0_amd64.deb"
            }
        ]
    },
    "windows": {
        os: "Windows",
        icon: "/images/os/windows-icon.png",
        version: "v24.7.0",
        arch: "64 bit",
        release: "Jul 02, 2024",
        downloads: [{
                "name": "Download",
                "link": "https://packages.robomotion.io/releases/windows/RobomotionSetup-x64-24.7.0.exe"
            }
        ]
    },
    "macos": {
        os: "MacOS",
        icon: "/images/os/macos-icon.png",
        version: "v24.7.0",
        arch: "64 bit",
        release: "Jul 02, 2024",
        downloads: [{
            "name": "ARM (M1/M2)",
            "link": "https://packages.robomotion.io/releases/macos-arm/Robomotion-24.7.0.dmg"
        },
        {
            "name": "Intel (AMD64)",
            "link": "https://packages.robomotion.io/releases/macos/Robomotion-24.7.0.dmg"
        }]
    },
    "rpi": {
        os: "Raspberry Pi",
        icon: "/images/os/rpi-icon.png",
        version: "v23.4.1",
        arch: "32 bit",
        release: "Apr 23, 2023",
        downloads: [{
            "name": "Download",
            "link": "https://packages.robomotion.io/releases/raspberry/robomotion_23.4.1_armhf.deb"
        }]
    }
};


export default robots;

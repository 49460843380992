import {
    createStyles,
    Title,
    Text,
    Container,
    Center
} from '@mantine/core';
import colors from '../../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        position: 'relative',
        backgroundColor: 'white',
        paddingBottom: 100,

        '@media (max-width: 755px)': {
            paddingTop: 80,
        },
    },

    hero: {
        position: 'relative',
        zIndex: 1,
    },

    title: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: 42,
        lineHeight: "1.2",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        paddingTop: theme.spacing.xl,

        [theme.fn.smallerThan('lg')]: {
            fontSize: 32,
        },
    },

    img: {
        maxWidth: 650,
    },
    subTitle: {
        color: colors.PrimaryText,
        textAlign: "center",
        letterSpacing: "-0.02em",
        lineHeight: "1.4",
        fontSize: 26,
        marginBottom: 25,
    },
    description: {
        color: colors.PrimaryText,
        textAlign: "center",
        letterSpacing: "-0.02em",
        lineHeight: "1.4",
        fontSize: 24,
        marginBottom: 15,
    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

}));

const Body = ({data}) => {
    const { classes } = useStyles();

    return (
        <Container className={classes.wrapper} size={"%100"} pb={100} pt={50} m={0} >
            <div className={classes.hero}>
                <Title className={classes.title}>
                    { data && data.hero ? data.body.tagline: "" } 

                </Title>

                <Container px={30} size={1100}>
                    <Center>
                            <Text size="xl" className={classes.description} p={20}>
                                { data && data.hero ? data.body.description: "" } 
                            </Text>
                    </Center>
                </Container>
            </div>
        </Container>
    );
}

export default Body;

import { Helmet } from 'react-helmet';
import Users from '../shared/Users';
import Discord from '../shared/Discord';
import ROICalculator from './ROICalculator';


const ROI = () => {
    const title = "ROI Calculator - Robomotion RPA"
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta name="twitter:title" content={title} />
            </Helmet>
            <ROICalculator />
            <Discord />
        </>
    );
}

export default ROI;
import {
    createStyles,
    Title,
    Text,
    Mark,
    Container,
    Button,
    Center
} from '@mantine/core';

import colors from '../../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin:"auto",
        paddingTop: 80,

        '@media (max-width: 755px)': {
            paddingTop: 80,
            paddingBottom: 60,
        },
    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 80,
    },

    title: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: "4.2em",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        marginBottom: theme.spacing.sm,
        marginTop: theme.spacing.md,
        fontFamily: 'Barlow',

        [theme.fn.smallerThan('lg')]: {
            fontSize: "3em",
        },
    },

    subTitle: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: "1.8em",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        marginBottom: theme.spacing.sm,
        marginTop: theme.spacing.md,
        fontFamily: 'Barlow',

        [theme.fn.smallerThan('lg')]: {
            fontSize: "3em",
        },
    },

    description: {
        color: colors.PrimaryText,
        textAlign: 'center',
        fontSize: 30,
        maxWidth: "690px",
        lineHeight: 1.7,
        fontFamily: 'Barlow',

    },

    underlined: {
        textDecoration: "underline wavy #2196F3"
    },

    subDescription: {
        marginTop: "40px",
        textAlign: 'center',
        fontSize: 16,
    },

    ratingBase: {
        textAlign: 'center',
        fontSize: 18,
        maxWidth: "300px",
        fontWeight: "400",
        marginTop: "-10px",
        color: colors.PrimaryText,
    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenSM: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

}));

const Hero = () => {

    const { classes } = useStyles();

    return (
        <Container className={classes.wrapper} size={1200}>
            <div className={classes.hero}>

                <Title className={classes.title}>
                    Become a Robomotion <Text component="span"
                        className={classes.highlight}
                        inherit
                        variant="gradient"
                        gradient={{ from: colors.Secondary, to: colors.Secondary }}
                    >
                    <br></br>Solution Expert </Text>{''} Partner
                    
                </Title>

                <Container p={0} size={1000}>
                    <Center>
                        <Text className={classes.description}>
                        If you are a <Text component="span" inherit fw={600}> <Mark p={5}>Freelancer</Mark> </Text> Robomotion <Text component="span" inherit fw={600}> <Mark p={5}>Solution Experts Partnership</Mark> </Text> is for you.
                        </Text>
                    </Center>
                </Container>

                <Container p={0} size={900} className={classes.subDescription}>
                    <Text size="lg" >
                        <Button size="xl" radius="md"  onClick={() => {window.location.href = "https://robomotion.firstpromoter.com"}} variant="gradient" gradient={{ from: colors.Primary, to: colors.Primary }}>
                            APPLY NOW
                        </Button>
                    </Text>
                    <Text size="xl"  p={0} mt={-5}>
                        <div><p><b>15000+</b> teams around the world have chosen Robomotion!</p></div>
                    </Text>

                </Container>

            </div>
        </Container>

    );
}

export default Hero;

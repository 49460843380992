import {
    createStyles,
    Title,
    Text,
    Container,
    Button,
    Center,
    Rating,
    Mark,
    Flex,
    Accordion,
    Card,
    SimpleGrid,
    Avatar,
    Image,
    Badge,
    Tabs,
    Group,
    Stack,
} from '@mantine/core';


import data from './data.jsx'
import { IconArrowRight } from '@tabler/icons';

import colors from '../../../const/colors';
import FeatureView from './FeatureView'

const useStyles = createStyles((theme) => ({
    

    titleAccordion: {
        textAlign: 'left',
        fontWeight: 600,
        fontSize: "1.6em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    wrapper: {
        margin:"auto",
        paddingTop: 0,
        backgroundColor:colors.PrimaryBackground,

    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 80,
    },

    title: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "3.5em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    subTitle: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "2em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    description: {
        color: colors.Primary,
        textAlign: 'center',
        fontSize: 24,
        maxWidth: "900px",
        fontFamily: 'Barlow',
        
    },
    
    descriptionMobile: {
        color: colors.Primary,
        textAlign: 'left',
        fontSize: 24,
        maxWidth: "800px",
        fontFamily: 'Barlow',
        
    },

    descriptionAccordion: {
        color: colors.Primary,
        textAlign: 'left',
        fontSize: 20,
        fontFamily: 'Barlow',
        
    },

    highlight_1: {
        display: "inline-block",
        background: colors.Camboge,
        color: colors.Info
    },

    underlined: {
        textDecoration: "underline wavy #2196F3"
    },

    subBody: {
        marginTop: "40px",
        textAlign: 'center',
        fontSize: 20,
    },

    mainCard: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: "400",
        backgroundColor: colors.Transparent,
    },

    appCard: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: "400",
        backgroundColor: colors.Transparent,
    },

    appName: {
        fontFamily: 'Barlow',
        fontSize: 22,

    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenSM: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },
    highlight: {
        textDecoration: 'underlined'
    }

}));

function AppBox(name, logo, classes, asset) {
    return (
      <Card className={classes.appCard}>
        <Card.Section>
            <Center>
                <Image
                    src={logo}
                    width={120}
                    alt={name}
                />
            </Center>
        </Card.Section>
  
        <Center>
          <Text  className={classes.appName} fw={600}>{name}</Text>
        </Center>
  
      </Card>
    );
  }

  function AccordionLabel( label, image, description, classes) {
    return (
        <Flex
        mih={50}
        gap="md"
        justify="flex-start"
        align="flex-start"
        direction="row"
        wrap="nowrap"
      >
            <Avatar src={image} radius={0} size="xl" />
            <div>
            <Text className={classes.titleAccordion}>
                    {label}
            </Text>
            <Text className={classes.descriptionAccordion} >
                {description}
            </Text>
            </div>
            </Flex>
    );
  }


  function AccordionBody(feature, classes) {
    return (
        <Container p={0} m={0} size={765}>
            <Container p={0} m={0}>
                <Card className={classes.infoCard} pr={30}>
                    <Text className={classes.descriptionMobile}  >
                        {feature.description}
                    </Text>
                </Card>
                <Card className={classes.infoCard} p={0}>
                    <Image p={0} m={0} w={520} src={feature.image} alt="Feature image"   radius="md" />
                </Card>
            </Container>
            <Card className={classes.infoCard} p={0} m={0}>
                {feature.wip ?  
                <Button size="lg" radius="md" my="xl"  variant="gradient" gradient={{ from: colors.Accent1, to: colors.Accent1 }} >Upcoming</Button> 
                : 
                <Button rightIcon={<IconArrowRight />} size="lg" radius="md" my="xl" onClick={() => {window.open(feature.url, '_blank', 'noopener,noreferrer');}} variant="gradient" gradient={{ from: colors.Primary, to: colors.Primary }}>
                    Try {feature.title.highlight} {' '} {feature.title.normal}
                </Button>}
            </Card>
        </Container>
    );
  }

  function GetList(data, classes) {

    const lists = data.map((item) => (
        <Accordion.Item value={item.topTitle} key={item.topTitle}>
            <Accordion.Control>
                { AccordionLabel(item.title.highlight + " " + item.title.normal , item.logo, item.topTitle, classes) }
            </Accordion.Control>
            <Accordion.Panel>
                { AccordionBody(item, classes) }
            </Accordion.Panel>
        </Accordion.Item>
    ));

    return (
        <>
            {lists}
        </>
    );

}

const Apps = () => {

    const { classes } = useStyles();

    return (
        <Container className={classes.wrapper} size={1200}>
            <div className={classes.hero}>
                <Center>
                    <Card  className={classes.mainCard} pb={50} pt={100}>
                        <Title className={classes.title}>
                            Robomotion
                            <Text component="span"
                                className={classes.highlight}
                                inherit
                                variant="gradient"
                                gradient={{ from: colors.Secondary, to: colors.Secondary }}
                            >
                            {' '}<b>App Store</b></Text>{' '}
                            
                        </Title>
                        <Container p={0}>
                            <Center>
                                <Text className={classes.subTitle}>
                                Discover apps that showcase the capabilities of our platform
                                </Text>
                            </Center>
                        </Container>

                        <Container p={0} pb={50}>
                            <Center>
                                <Text className={classes.description}>                                    
                                Streamline your marketing efforts by integrating Robomotion Apps into your workflow. Our apps are designed to enhance <b>lead generation</b> and <b>content creation</b>, improving efficiency and cutting costs.
                                </Text>
                            </Center>
                        </Container>

                        <Container p={0} m={0} size={1200} className={classes.hiddenMobile}>
                            <Tabs defaultValue="seo_ai_writer" p={0} m={0}>
                                <Tabs.List grow justify="space-between" p={0} m={0}>
                                    <Tabs.Tab value="seo_ai_writer" color="yellow.5">{AppBox("SEO AI Writer", "./images/apps/SEOWriter_SM.png", classes)}</Tabs.Tab>                                    
                                    <Tabs.Tab value="ai_video_dubber" color="red.5">{AppBox("Video Dubber", "./images/apps/VideoDubber_SM.png", classes)}</Tabs.Tab>
                                    <Tabs.Tab value="outreach_video_creator" color="cyan.4">{AppBox("Outreach Video Creator", "./images/apps/OutreachVideoCreator_SM.png", classes)}</Tabs.Tab>
                                    <Tabs.Tab value="short_generator" color="yellow.6">{AppBox("Clip Generator", "./images/apps/ShortGenerator_SM.png", classes)}</Tabs.Tab>
                                </Tabs.List>
                                <Tabs.Panel value="seo_ai_writer">
                                    { FeatureView(data[0]) }
                                </Tabs.Panel>

                                <Tabs.Panel value="short_generator">
                                    { FeatureView(data[3]) }
                                </Tabs.Panel>

                                <Tabs.Panel value="ai_video_dubber">
                                    { FeatureView(data[1]) }
                                </Tabs.Panel>

                                <Tabs.Panel value="outreach_video_creator">
                                    { FeatureView(data[2]) }
                                </Tabs.Panel>
                            </Tabs>
                        </Container>

                        <Container p={0} m={0} size={1200} className={classes.hiddenDesktop}>
                            <Accordion chevronPosition="right" variant="contained">
                                {GetList(data, classes)}
                            </Accordion>
                        </Container>
                    </Card>
                </Center>
            </div>
        </Container>

    );
}

export default Apps;

import {
    createStyles,
    Title,
    Text,
    Container,
    Center
} from '@mantine/core';
import colors from '../../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        position: 'relative',
        paddingTop: 80,
        backgroundColor: '#f5f0f0',

        '@media (max-width: 755px)': {
            paddingTop: 80,
        },
    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 80,
    },

    title: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: 32,
        lineHeight: "1.2",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        marginBottom: theme.spacing.xl,

        [theme.fn.smallerThan('lg')]: {
            fontSize: 24,
        },
    },

    description: {
        color: colors.PrimaryText,
        textAlign: 'center',
        letterSpacing: "-0.02em",
        lineHeight: "1.4",
        fontSize: 20,
    },

    subDescription: {
        marginTop: "80px",
        textAlign: 'center',
        fontSize: 16,
    },


    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

}));

const SolutionCases = ({ data }) => {

    const items = data ? data.cases : [];

    const { classes } = useStyles();

    return (
        <Container className={classes.wrapper} size={"%100"} p={0} m={0} pt={96}>
        <>
            {items && items.length > 0 ? items.map((item, index) => (
                <Container key={index}>
                    <div  className={classes.hero}>
                        <Title className={classes.title}>
                            {item.title}
                        </Title>
                        <Container p={0} size={1100}>
                            <Center>
                                <Text size="lg" className={classes.description}>
                                    {item.description}
                                </Text>
                            </Center>
                        </Container>
                    </div>
                </Container>
            )) : ""}
        </>
        </Container>
    );
}
export default SolutionCases;


import { Container, Title, Accordion, createStyles } from '@mantine/core';
import colors from '../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin:"auto",
        paddingTop: 40,
        paddingBottom: 40,

    },

    hero: {
        position: 'relative',
        zIndex: 1,
    },

    title: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: "2em",
        lineHeight: "1.2",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,

    },

    subTitle: {
        color: colors.PrimaryText,
        textAlign: 'center',
        letterSpacing: "-0.02em",
        lineHeight: "1.4",
        fontSize: 48,
        fontWeight: 800,
    },

    description: {
        color: colors.PrimaryText,
        textAlign: 'center',
        letterSpacing: "-0.02em",
        lineHeight: "1.4",
        fontSize: 24,
        marginTop:60,
    },

    subDescription: {
        marginTop: "20px",
        textAlign: 'center',
        fontSize: 16,
    },


    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

}));

const faqs = [

    {
        title: 'What is Robomotion App Store?',
        description: `The Robomotion App Store features a wide range of applications developed using Robomotion, accessible to users without any additional fees. Our library of applications is continuously expanding, with a current focus on marketing-oriented tools. Users can use any application, subject to the number of Application Robots they possess, which determines how many applications can be run concurrently. In the future, we plan to open our App Store to external developers, further broadening the variety and utility of applications available.`,
        value: '8'
    },     
    {
        title: 'What are AI Credits?',
        description: `Robomotion is an automation platform that integrates with numerous third-party systems, including AI services such as OpenAI, Replicate, Hugging Face, and more. Accessing these services requires subscriptions. Robomotion provides the option for users to either employ their own API keys or to utilize our AI Credits. By default, applications from our App Store leverage Robomotion AI Credits. For those who are more technically inclined or considered power-users, we offer the ability to customize application flows by cloning the app. This customization includes the option to update flows to use your own API keys, giving you greater control and flexibility over your automation projects.`,
        value: '6'
    },
    {
        title: 'What are Proxy Requests?',
        description: `Proxy Requests refer to the use of intermediary servers to communicate between Robomotion robots and the target web services or websites. This feature is crucial for scenarios where direct access might be restricted or for simulating requests from different geographical locations. It enhances the capabilities of automation projects by allowing them to bypass network restrictions, enhance security, and manage IP-based access controls. Users can configure their robots to route requests through proxies, thereby masking the original source of the request and leveraging the proxy's IP address. This functionality is particularly useful for web scraping, data collection, and accessing geo-restricted content, ensuring smoother and more versatile automation workflows. For instance, our SEO AI Writer application from our App Store utilizes Proxy Requests to scrape Google Search Results.`,
        value: '7'
    },
    {
        title: 'What is Storage Used For?',
        description: `Every application needs storage to keep its state or to save results it creates. This storage is used for both Apps from the App Store and Apps built by developers in their own workspace. Each application instance gets its own storage space to use while it's running. To manage your storage space and make more room, you can delete the application instance from the Robomotion Apps.`,
        value: '-2'
    },
    {
        title: 'What is Unlimited Local Execution Time?',
        description: `Robots executing flows on customer-owned machines are not subject to charges for execution time. This applies to various setups, including a developer's personal computer, a Dedicated Server or a Virtual Private Server (VPS) owned by the customer, or the customer's own servers. "Local Execution" specifically refers to any execution carried out on a device owned by the user where the robot software is installed, with the exception of our Cloud Run environment. This means users can run their automation flows on their own hardware without worrying about time limits or additional costs.`,
        value: '4'
    },
    {
        title: 'What is Cloud Execution Time?',
        description: 'In contrast to Local Execution, when robots run in our Cloud Environment, users are charged for the runtime consumed in this scenario. Our subscription plans include a set number of hours for cloud execution that reset at the beginning of each subscription cycle, providing flexibility and scalability for automation needs.',
        value: '5'
    },
        {
            title: 'What is a Flow?',
            description: "A Flow refers to an automation project created by developers using the Robomotion Flow Designer. It consists of a sequence of steps, which can be unlimited, designed to automate tasks. Flows are executed by robot software that can be installed on a developer's machine, a server, or run in our cloud environment on-demand. This flexibility allows for a wide range of automation possibilities, from simple tasks to complex processes, tailored to various operational needs.",
            value: '-1'
        },
        {
            title: 'What is a Robot?',
            description: "In the context of Robotic Process Automation (RPA), a robot refers to a digital worker or software agent designed to automate tasks by mimicking human interactions with digital systems and software. This technology enables the creation of automated solutions that can perform repetitive tasks without human intervention. You have the flexibility to deploy a robot on your own machine to run automations on-premises, ensuring complete control and security of your data. Alternatively, you can opt for our cloud run option, which allows you to execute automations in our cloud environment without the need for any installations on your part. This provides a seamless and scalable way to leverage automation technology, regardless of your infrastructure.",
            value: '0'
        },
        {
            title: 'What is an Application Robot?',
            description: "An Application Robot functions as a designated slot for running applications. With Robomotion, users have the flexibility to utilize applications from our App Store or develop and deploy their own custom applications. These can be managed and executed directly from the Robomotion Apps dashboard. The concept of an Application Robot essentially governs the quantity of applications you can run concurrently. An application robot can only run a single flow, which is the designated flow of the Application Instance.",
            value: '1'
        },
        {
            title: 'What is a Development Robot?',
            description: 'A Development Robot is a specialized robot in Robomotion, created specifically for developers to design flows for automation projects. Developers utilize it within the Robomotion Flow Designer to construct these flows. It has the capability to execute any flow configured in the Designer. However, it cannot schedule flows or monitor for triggers. Its main function is to assist in the testing and refinement of flows during the development phase, ensuring they are ready for deployment in real-world scenarios.',
            value: '2'
        },
        {
            title: 'What is a Production Robot?',
            description:  'A Production Robot is utilized when a flow is prepared for deployment in a live environment. These robots are capable of running any flow, allowing for the scheduling of flows as needed. Additionally, Production Robots are equipped to monitor multiple trigger events, such as changes in the file system, email notifications, HTTP requests, queue insertions, and hardware triggers. They can initiate flows based on these trigger events, making them essential for automating tasks in real-time operational settings.',
            value: '3'
        },

    ];



const FAQ = () => {
    const { classes } = useStyles();

    const ListQuestions = (x) => {
        return x.map((faq, index) => (
            <Accordion.Item key={index} className={classes.item} value={faq.value}>
                <Accordion.Control>{faq.title}</Accordion.Control>
                <Accordion.Panel>{faq.description}</Accordion.Panel>
            </Accordion.Item>
        ));
    }

    return (
        <Container size="100%" className={classes.wrapper}>
            <Container size="sm" className={classes.wrapper}>

            <Title align="center" className={classes.title} pb={50}>
                Frequently Asked Questions
            </Title>

            <Accordion variant="separated">
                {ListQuestions(faqs)}
            </Accordion>
            </Container>
        </Container>
    );
}

export default FAQ;
import {
    createStyles,
    Title,
    Text,
    Container,
    Button
} from '@mantine/core';
import colors from '../../const/colors.jsx';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin:"auto",
        paddingTop: 120,
        backgroundColor: colors.PrimaryBackground,

    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 100,
    },

    title: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "3.5em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    subTitle: {
        textAlign: 'center',
        fontWeight: 300,
        fontSize: "2em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    description: {
        color: colors.Primary,
        textAlign: 'center',
        fontSize: 24,
        maxWidth: "800px",
        fontFamily: 'Barlow',
        
    },

    underlined: {
        textDecoration: "underline wavy #2196F3"
    },

    subDescription: {
        marginTop: "40px",
        textAlign: 'center',
        fontSize: 16,
    },

    ratingBase: {
        textAlign: 'center',
        fontSize: 18,
        maxWidth: "300px",
        fontWeight: "400",
        marginTop: "-10px",
        color: colors.PrimaryText,
    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenSM: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

}));

const CTA = () => {
    const { classes } = useStyles();

    return (
        <Container className={classes.wrapper} size={"100%"}>
            <Container  size={1200}>
                <div className={classes.hero}>
                    <Title className={classes.title}>
                        Ready to hire software robots?
                    </Title>
                    <Container p={0} size={900} className={classes.subDescription}>
                        <Text size="lg" >
                            <Button color="gray" variant="light"  size="lg" radius="md"  onClick={() => {window.location.href = "https://app.robomotion.io/create"}} >
                                TRY ROBOMOTION FOR FREE
                            </Button>
                        </Text>
                        <Text size="xs" color="dimmed" p={0} mt={-5}>
                            <div><p>No Credit Card Required. Cancel Anytime.</p></div>
                        </Text>
                    </Container>
                </div>
            </Container>
        </Container>

    );
}

export default CTA;

import {
    createStyles,
    Container,
    Card,
    Center,
    Paper,
    Button,
    Title,
    List,
    Text,
    Grid,
    Image,
    SimpleGrid,
    useMantineTheme,
    AspectRatio,
} from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { useMediaQuery } from '@mantine/hooks';
import { IconArrowRight } from '@tabler/icons';

import colors from '../../../const/colors';
import { useRef } from 'react';

import {
    IconCheck,
} from '@tabler/icons';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin: "auto",
    },

    hero: {
        position: 'relative',
        zIndex: 1,
    },

    title: {
        textAlign: 'left',
        fontWeight: 700,
        fontSize: "1.6em",
        color: colors.Accent1,
        fontFamily: 'Barlow',

        [theme.fn.smallerThan('lg')]: {
            fontSize: "1.6em",
        },
    },

    topTitle: {
        textAlign: 'center',
        fontSize: "26px",
        fontFamily: 'Barlow',
        fontWeight: "800",
        color: colors.Primary,
        textTransform: "uppercase"
    },

    bottomTitle: {
        textAlign: 'left',
        fontWeight: 800,
        fontSize: "26px",
        fontFamily: 'Inter'
    },

    description: {
        color: colors.PrimaryText,
        textAlign: 'left',
        fontSize: 20,
    },

    listItem: {
        color: colors.Accent1,
        textAlign: 'left',
        fontSize: 18,
    },

    highlight: {
        fontWeight: 800,

    },
    underlined: {
        textDecoration: "underline wavy #2196F3"
    },


    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

    infoCard: {
        backgroundColor: colors.WhiteText,
    },

    imageCard: {
        backgroundColor: colors.TertiaryBackground,
        maxWidth: 700,
    },

    image: {
        minWidth: "520px",
    },

    starter: {
        fontSize: "18px",
        fontWeight: "800",
        lineHeight: "22px",
        color: colors.Tertiary,
    },

    info: {
        fontSize: "18px",
        fontWeight: "500",
        lineHeight: "22px",
        color: colors.Tertiary,
    },

}));
function GetList(data, classes) {

    const lists = data.map((item) => (
        <List.Item key = {item.rest} className={classes.listItem}>
            <Text  component="span" className={classes.starter}>{item.start}</Text>
            {' '}
            <Text  component="span" className={classes.info}>{item.rest}</Text>
        </List.Item>
    ));

    return (
        <List  type="ordered" spacing="md" size="xl" m={10} px={40} center >
            {lists}
        </List>
    );

}

 

const FeatureView = (feature) => {

    const { classes } = useStyles();

    return (
        <Card className={classes.mainCard} shadow={"sm"} p={25}>
                <SimpleGrid spacing={20} cols={1}>
                    <Text className={classes.topTitle}  >
                        {feature.topTitle}
                    </Text>
                    <Container p={0} m={0}>
                        <SimpleGrid spacing={0} cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }, { maxWidth: 'lg', cols: 2 }]}>
                            <Card className={classes.infoCard} pr={30}>
                                <Title className={classes.title} pb={6} >
                                    {feature.title.highlight} {' '} {feature.title.normal}
                                </Title>
                                <Text className={classes.description}  >
                                    {feature.description}
                                </Text>
                            </Card>
                            <Card className={classes.infoCard} p={0}>
                                { feature.asset == 'gif' ?
                                    <Image p={0} m={0} w={520} src={feature.image} alt="Feature image"   radius="md" />
                                :
                                <AspectRatio ratio={16 / 9} frameBorder={0}>
                                    <iframe
                                        src={feature.video}
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    />
                                </AspectRatio>
                                }
                            </Card>
                        </SimpleGrid>
                    </Container>
                    <Center  p={0} m={0}>
                        <Card p={0} m={0}>
                            {feature.badge}
                        </Card>
                    </Center>
                    <Card className={classes.infoCard} p={0} m={0}>
                        {feature.wip ?  
                        <Button size="lg" radius="md"variant="gradient" gradient={{ from: colors.Accent1, to: colors.Accent1 }} >Upcoming</Button> 
                        : 
                        <span>
                        <Button rightIcon={<IconArrowRight />} size="lg" radius="md" onClick={() => {window.open(feature.url, '_blank', 'noopener,noreferrer');}} variant="gradient" gradient={{ from: colors.Primary, to: colors.Primary }}>
                            Try {feature.title.highlight} {' '} {feature.title.normal}
                        </Button>
                        {feature.edit ?
                        <Button ml={16} rightIcon={<IconArrowRight />} size="lg" radius="md" onClick={() => {window.open(feature.edit, '_blank', 'noopener,noreferrer');}} variant="gradient" gradient={{ from: colors.Secondary, to: colors.Secondary }}>
                            Edit {feature.title.highlight} {' '} {feature.title.normal}
                        </Button>: null}

                        </span>
                        }
                    </Card>
                </SimpleGrid>
        </Card>
    );
}

export default FeatureView;


/*


const FeatureView = (feature) => {

    const { classes } = useStyles();

    return (

        <Center>
            <Card className={classes.infoCard} >
                <Title className={classes.title} pt={20}>
                    {feature.title.highlight} {' '} {feature.title.normal}
                </Title>
                <Text className={classes.topTitle}  pb={10} >
                    {feature.topTitle}
                </Text>
                <Center p={30}>
                    <Card shadow="sm" p={0} m={0} radius="lg" >
                        <Image p={0} m={0} src={feature.image} alt="Feature image"  className={classes.image} />
                    </Card>
                </Center>
                <Center>
                    <Text className={classes.description}  pb={10}>
                        {feature.description}
                    </Text>
                </Center>
                <Center>
                    {GetList(feature.list, classes)}
                    </Center>
                    {feature.wip ?  
                    <Button size="lg" radius="md" my="xl"  variant="gradient" gradient={{ from: colors.Accent1, to: colors.Accent1 }} >Upcoming</Button> 
                    : 
                    <Button rightIcon={<IconArrowRight />} size="lg" radius="md" my="xl" onClick={() => {window.open(feature.url, '_blank', 'noopener,noreferrer');}} variant="gradient" gradient={{ from: colors.Primary, to: colors.Primary }}>
                        Try {feature.title.highlight} {' '} {feature.title.normal}
                    </Button>}
                </Card>
            </Center>
        );
    }
    
    export default FeatureView;
    

*/

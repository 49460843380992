import React, { useEffect } from 'react';
import posthog from 'posthog-js';
import Header from './components/shared/Header'
import Footer from './components/shared/Footer'
import Home from './components/home/Index'
import Webinars from './components/webinars/Index'
import Webinar from './components/webinar/Index'
import Experts from './components/partners/experts/Index'
import Affiliate from './components/partners/affiliate/Index'
import Enterprise from './components/partners/enterprise/Index'
import Industries from './components/solutions/industries/Index'
import UseCases from './components/solutions/use-cases/Index'
import Downloads from './components/download/Index'
import Pricing from './components/pricing/Index'
import ROI from './components/roi/Index'
import Legal from './components/legal/Index'
import About from './components/about/Index'
import Blog from './components/blog/Index'
import Blogs from './components/blogs/Index'

import {
  BrowserRouter as Router,
  useRoutes
} from "react-router-dom";
import './App.css';
import { MantineProvider } from '@mantine/core'
import Notification from './components/shared/Notification'

const AppRouter = () => {
  let routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/about", element: <About /> },
    { path: "/downloads/", element: <Downloads /> },
    { path: "/pricing/", element: <Pricing /> },
    { path: "/roi-calculator/", element: <ROI /> },
    { path: "/webinar/", element: <Webinars /> },
    { path: "/webinar/", element: <Webinars /> },
    { path: "/webinar/:slug", element: <Webinar /> },
    { path: "/legal/:slug", element: <Legal /> },
    { path: "/partners/solution-experts", element: <Experts /> },
    { path: "/partners/affiliate-partner", element: <Affiliate /> },
    { path: "/partners/enterprise-partner", element: <Enterprise /> },
    { path: "/solutions/:slug", element: <Industries /> },
    { path: "/solutions/use-case/:slug", element: <UseCases /> },
    { path: "/blog/:slug", element: <Blog /> },
    { path: "/blog", element: <Blogs /> },


    // ...
  ]);
  return routes;
};


function App() {

  useEffect(() => {
    // Initialize PostHog
    posthog.init('phc_th1CDd5NpHWHVd5kzoBpYUjCAQXuDhpREJ4QMNOs6SI', { 
      api_host: 'https://posthog.robomotion.io' 
    });
  }, []);

  return (
    <>
      <MantineProvider
        theme={{
          fontFamily: 'Inter, sans-serif',
          fontFamilyMonospace: 'Inter, Courier, monospace',
          headings: { fontFamily: 'Inter, sans-serif' },

        }}
      >
{/*      <Notification /> */}
      <Header />
        <Router>
          <AppRouter />
        </Router>
      <Footer />
      </MantineProvider>
    </>
  );
}

export default App;

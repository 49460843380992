import {
    createStyles,
    Title,
    Text,
    Container,
    Button,
    Center
} from '@mantine/core';

import colors from '../../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin:"auto",
        paddingTop: 80,

        '@media (max-width: 755px)': {
            paddingTop: 80,
            paddingBottom: 60,
        },
    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 80,
    },

    title: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: "4.2em",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        marginBottom: theme.spacing.sm,
        marginTop: theme.spacing.md,
        fontFamily: 'Barlow',

        [theme.fn.smallerThan('lg')]: {
            fontSize: "3em",
        },
    },

    subTitle: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: "1.8em",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        marginBottom: theme.spacing.sm,
        marginTop: theme.spacing.md,
        fontFamily: 'Barlow',

        [theme.fn.smallerThan('lg')]: {
            fontSize: "3em",
        },
    },

    description: {
        color: colors.PrimaryText,
        textAlign: 'center',
        fontSize: 30,
        maxWidth: "850px",
        lineHeight: 1.7,
        fontFamily: 'Barlow',

    },
    underlined: {
        textDecoration: "underline wavy #2196F3"
    },

    subDescription: {
        marginTop: "40px",
        textAlign: 'center',
        fontSize: 16,
    },

    ratingBase: {
        textAlign: 'center',
        fontSize: 18,
        maxWidth: "300px",
        fontWeight: "400",
        marginTop: "-10px",
        color: colors.PrimaryText,
    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenSM: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },


}));

const Hero = ({data}) => {
    const { classes } = useStyles();

    return (
        <Container className={classes.wrapper} size={1200}>
            <div className={classes.hero}>
                <Title className={classes.title}>
                    { data && data.hero ? data.hero.tagline: "" } 
                </Title>

                <Container p={0} size={1100}>
                    <Center>
                        <Text className={classes.description}>
                            { data && data.hero ? data.hero.description: "" } 
                        </Text>
                    </Center>
                </Container>

                <Container p={0} size={900} className={classes.subDescription}>
                <Text size="lg" >
                        <Button size="lg" radius="md"  onClick={() => { window.location.href = "https://calendly.com/robomotion-sales/demo" }} variant="gradient" gradient={{ from: colors.Primary, to: colors.Primary }}>
                        Book a Demo
                        </Button>
                    </Text>
                    <Text size="xs" color="dimmed" p={0} mt={-5}>
                        <div><p>Empower Your Business with RPA! Schedule Your Robomotion Demo.</p></div>
                    </Text>
                    {/*
                    <Text size="xl" color="">
                        <div><p><b>9000+</b> teams around the world have chosen Robomotion!</p></div>
                    </Text>
                    */}

                </Container>
                {/* 
                <Container pt={40} size={600}>
                        <Center>
                            <img src="/images/logos-line.png" alt="Robomotion's business partners"></img>
                        </Center>
                </Container>
                */}
            </div>
        </Container>

    );
}

export default Hero;

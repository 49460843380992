import webinars from './data';

import {
    createStyles,
    Container,
    Center,
	SimpleGrid,
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
    wrapper: {
        position: 'relative',
        margin:"auto",
        display: 'flex',
        paddingBottom: 80,

        '@media (max-width: 755px)': {
            paddingBottom: 60,
        },
    },
	images: {
		maxWidth:450,
		maxHeight: 432,
		borderRadius: 5,
	}
}));


const Records = () => {
	const { classes } = useStyles();

	return (
		<Center>
			<Container className={classes.wrapper} size={1200}>
				<SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }, { maxWidth: 'lg', cols: 2 }]}>
					{webinars.map(w => (
						<div key={w.slug}><a href={w.url}><img className={classes.images} src={w.image} alt="Robomotion Live Webinar" /></a></div>
					))}
				</SimpleGrid>
			</Container>
		</Center>

	);
}

export default Records;

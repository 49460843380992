import {
    createStyles,
    Title,
    Text,
    Container,
    Center
} from '@mantine/core';

import colors from '../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin:"auto",
        paddingTop: 80,

        '@media (max-width: 755px)': {
            paddingTop: 80,
            paddingBottom: 60,
        },
    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 80,
    },

    title: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "4.5em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    subTitle: {
        textAlign: 'center',
        fontWeight: 300,
        fontSize: "2em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    description: {
        color: colors.Primary,
        textAlign: 'center',
        fontSize: 30,
        maxWidth: "800px",
        fontFamily: 'Barlow',
        
    },

    underlined: {
        textDecoration: "underline wavy #2196F3"
    },

    subDescription: {
        marginTop: "40px",
        textAlign: 'center',
        fontSize: 16,
    },

    ratingBase: {
        textAlign: 'center',
        fontSize: 18,
        maxWidth: "300px",
        fontWeight: "400",
        marginTop: "-10px",
        color: colors.PrimaryText,
    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenSM: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

}));

const Hero = () => {

    const { classes } = useStyles();

    return (
        <Container className={classes.wrapper} size={1200}>
            <div className={classes.hero}>
                <Title className={classes.title}>
                Robomotion<Text component="span"
                        className={classes.highlight}
                        inherit
                        variant="gradient"
                        gradient={{ from: colors.Secondary, to: colors.Secondary }}
                    >
                    <b> Blog </b> </Text>{''}
                    
                </Title>

                <Container p={0} size={1100}>
                    <Center>
                        <Text className={classes.description}>
                            Supercharge Your RPA Automations: Execute 10x Faster across <u><b>all platforms</b></u>, without limits.
                        </Text>
                    </Center>
                </Container>

            </div>
        </Container>

    );
}

export default Hero;

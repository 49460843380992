
import {
    createStyles,
	Card,
	Image,
    Center
} from '@mantine/core';	

const useStyles = createStyles((theme) => ({
	images: {

		borderRadius: 20
	},
	card: {
		backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
	},
}));


const PartnerCards = ({ name, logo, url }) => {
	const { classes } = useStyles();
	return (

		<Card   shadow="xs" m={5} p={10} radius="md" >
			<Center style={{ height: 200 }} p={40}>
				<a href={url}><Image className={classes.images} src={logo} alt={name} w="auto" fit="contain"/></a>
			</Center>
		</Card>
	);
}

export default PartnerCards;

import { Helmet } from 'react-helmet';
import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import frontmatter from 'frontmatter';
import Body from './Body';

const Blog = () => {
    const { slug } = useParams();
    const [post, setPost] = useState('');

    useEffect(() => {
        import(`./data/${slug}/index.md`).then(res => {
            fetch(res.default)
            .then(res => res.text())
            .then(res => setPost(res));
        })
        .catch(err => console.log(err));
    });

    const mrkdwn = frontmatter(post);
    const title = mrkdwn.data ? mrkdwn.data.title : "Legal - Robomotion RPA";
    return (        
        <>
        
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta name="twitter:title" content={title} />
            </Helmet>

            <Body content={mrkdwn.content} />
        </>
    );
}

export default Blog;
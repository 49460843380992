import Card from './Card';
import colors from '../../const/colors';

import {
    createStyles,
    Container,
    SimpleGrid
} from '@mantine/core';


const useStyles = createStyles((theme) => ({
    wrapper: {
        margin:"auto",
        paddingTop: 40,
        paddingBottom: 40,
        '@media (max-width: 755px)': {
        },
    },

    hero: {
        position: 'relative',
        zIndex: 1,
    },

    title: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: "5em",
        lineHeight: "1.2",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,

        [theme.fn.smallerThan('lg')]: {
            fontSize: "3em",
        },
    },

    subTitle: {
        color: colors.PrimaryText,
        textAlign: 'center',
        letterSpacing: "-0.02em",
        lineHeight: "1.4",
        fontSize: 48,
        fontWeight: 800,
    },

    description: {
        color: colors.PrimaryText,
        textAlign: 'center',
        letterSpacing: "-0.02em",
        lineHeight: "1.4",
        fontSize: 24,
        marginTop:60,
    },

    subDescription: {
        marginTop: "20px",
        textAlign: 'center',
        fontSize: 16,
    },


    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

}));



const PricingDeveloper = () => {

    const { classes } = useStyles();

    return (
        <>
            <Container className={classes.wrapper} size={1200}>
            <div className={classes.hero}>
                <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'sm', cols: 1 }, { maxWidth: 'md', cols: 2 }]}>
                    <Card plan="starter_developer" />
                    <Card plan="growth_developer" />
                    <Card plan="enterprise_developer" />
                </SimpleGrid>
            </div>
            </Container>
        </>

    );
}

export default PricingDeveloper;

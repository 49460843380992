
import EarningCalculator from './EarningCalculator';

import {
    createStyles,
    Title,
    Text,
    Container,
    Button,
    Center,
} from '@mantine/core';

import colors from '../../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin:"auto",

    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 80,
    },

    title: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: "4.2em",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        marginBottom: theme.spacing.sm,
        marginTop: theme.spacing.md,
        fontFamily: 'Barlow',

        [theme.fn.smallerThan('lg')]: {
            fontSize: "3em",
        },
    },

    subTitle: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: "1.8em",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        marginBottom: theme.spacing.sm,
        marginTop: theme.spacing.md,
        fontFamily: 'Barlow',

        [theme.fn.smallerThan('lg')]: {
            fontSize: "3em",
        },
    },

    description: {
        color: colors.PrimaryText,
        textAlign: 'center',
        fontSize: 30,
        maxWidth: "1000px",
        lineHeight: 1.7,
        fontFamily: 'Barlow',

    },

    underlined: {
        textDecoration: "underline wavy #2196F3"
    },

    subDescription: {
        marginTop: "40px",
        textAlign: 'center',
        fontSize: 16,
    },

    ratingBase: {
        textAlign: 'center',
        fontSize: 18,
        maxWidth: "300px",
        fontWeight: "400",
        marginTop: "-10px",
        color: colors.PrimaryText,
    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenSM: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

}));

const Calculator = () => {

    const { classes } = useStyles();

    return (
            <Container className={classes.wrapper} size={"%100"} pt={150}>
                <Center>
                    <div className={classes.hero}>
                        <Title className={classes.title}>
                                How much can I earn?
                        </Title>

                        <Container p={0} size={800}>
                            <Center>
                                <Text size="lg" className={classes.description}>
                                    Earn <b>40%</b> recurring commission for every new paying customer you refer.
                                </Text>
                            </Center>
                        </Container>

                        <Container p={0} size={800}>
                            <Center>
                                <EarningCalculator />
                            </Center>
                        </Container>


                        <Container p={0} size={800} className={classes.subDescription}>
                            <Text size="md" color="dimmed">
                                <div><p>Estimations based on monthly commissions when your referral purchases our <b>Pro plan.</b></p></div>
                            </Text>
                            <Text size="lg" color="dimmed" >
                                <Button size="lg" radius="md"  onClick={() => {window.location.href = "https://robomotion.firstpromoter.com"}} variant="gradient" gradient={{ from: colors.Primary, to: colors.Primary }}>
                                    APPLY NOW
                                </Button>
                            </Text>

                        </Container>
                    </div>
                </Center>
            </Container>

    );
}

export default Calculator;

import {
    createStyles,
    Header,
    Text,
    Container
} from '@mantine/core';
import colors from '../../const/colors.jsx';

const useStyles = createStyles((theme) => ({
    header: {
      backgroundColor: colors.TertiaryBackground,
      borderBottom: 0,
    },
  
    inner: {
      height: "56px",
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  
    links: {
      [theme.fn.smallerThan('sm')]: {
        display: 'none',
      },
    },
  
    burger: {
      [theme.fn.largerThan('sm')]: {
        display: 'none',
      },
    },
  
    link: {
      display: 'block',
      lineHeight: 1,
      padding: `${"8px"} ${"12px"}`,
      borderRadius: theme.radius.sm,
      textDecoration: 'none',
      color: theme.white,
      fontSize: theme.fontSizes.sm,
      fontWeight: 500,
  
      '&:hover': {
        
      },
    },
  
    linkLabel: {
      marginRight: "5px",
    },

    notification: {
        textAlign: 'center',
        fontSize: 17,
        color: colors.WhiteText,
        fontWeight: 400,
        paddingTop: 15,

        'a': {
          color: colors.WhiteText,
        },
    },

    hiddenMobile: {
        [theme.fn.smallerThan('lg')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('lg')]: {
            display: 'none',
        },
    },
  }));
  


const Notification = () => {
    const { classes } = useStyles();

    return (
        <Header height={56} className={classes.header} mb={20}>
        <Container>
          <div className={classes.inner}>
            <Text className={classes.notification}>
                <Text className={classes.hiddenMobile}>
                        Robomotion Bot Store is here! 🚀 RPA Developers, monetize your expertise! <u><b><a href="https://store.robomotion.io">Learn More</a></b></u>.
                </Text>
                <Text className={classes.hiddenDesktop}>
                        Robomotion Bot Store is here! 🚀 <u><b>Learn More</b></u>.
                </Text>
            </Text>
          </div>
        </Container>
      </Header>
    );
}

export default Notification;


import {
    createStyles,
    Title,
    Text,
    Container,
    SimpleGrid,
    Button,
    Center,
    Rating,
    Mark,
    Card,
    Image,
    Badge,
    Tabs,
    Group,
    Stack,
} from '@mantine/core';


import data from './data.jsx'

import colors from '../../../const/colors.jsx';
import FeatureView from './FeatureView.jsx'

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin:"auto",
        paddingTop: 0,
        backgroundColor:colors.PrimaryBackground,

        '@media (max-width: 755px)': {
            paddingTop: 50,
            paddingBottom: 60,
        },
    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 80,
    },

    title: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "3.5em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    subTitle: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "2em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    description: {
        color: colors.Primary,
        textAlign: 'center',
        fontSize: 24,
        maxWidth: "800px",
        fontFamily: 'Barlow',
        
    },

    highlight_1: {
        display: "inline-block",
        background: colors.Camboge,
        color: colors.Info
    },

    underlined: {
        textDecoration: "underline wavy #2196F3"
    },

    subBody: {
        marginTop: "40px",
        textAlign: 'center',
        fontSize: 20,
    },

    mainCard: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: "400",
        backgroundColor: colors.Transparent,
    },

    appCard: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: "400",
        backgroundColor: colors.Transparent,
    },

    appName: {
        fontFamily: 'Barlow',
        fontSize: 22,

    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenSM: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },
    highlight: {
        textDecoration: 'underlined'
    },
    bottomTitle: {
        fontWeight: 800,
        fontSize: "28px",
        color: colors.Primary,
    },

    infoCard: {
        backgroundColor: colors.Transparent,
    },

    innerCard: {
        backgroundColor: colors.Transparent,
    },

    extraCard: {
        backgroundColor: colors.SecondaryBackground,
        maxWidth: 900,
    },

}));


const AppsExtra = () => {

    const { classes } = useStyles();

    return (
        <Container className={classes.wrapper} size={1200} pb={100}>
            <div className={classes.hero}>
                <Center>
                    <Card  className={classes.mainCard} pb={40}>
                        <Title className={classes.title}>
                            Turn<b></b> 
                            <Text component="span"
                                className={classes.highlight}
                                inherit
                                variant="gradient"
                                gradient={{ from: colors.Secondary, to: colors.Secondary }}
                            >
                            {' '}<b>Automations</b></Text>{' '} Into Apps
                            
                        </Title>
                        <Container p={0}>
                            <Center>
                                <Text className={classes.subTitle}>
                                    Customize our Apps or Create your own Apps
                                </Text>
                            </Center>
                        </Container>

                        <Container p={0} pb={0}>
                            <Center>
                                <Text className={classes.description}>
                                You have multiple options for your Automation: You can <Text component="span" inherit fw={600}><Mark p={5}>schedule</Mark></Text> it to run at regular intervals, <Text component="span" inherit fw={600}><Mark p={5}>trigger</Mark></Text> it based on specific events, integrate it with a <Text component="span" inherit fw={600}><Mark p={5}>chatbot</Mark></Text>, or develop a user interface to utilize the automation as a <Text component="span" inherit fw={600}><Mark p={5}>backend</Mark></Text> service for your application.
                                </Text>
                            </Center>
                        </Container>
                    </Card>
                </Center>
                <Center className={classes.holder} p={0} m={0}>
                    <SimpleGrid cols={3} spacing={50} breakpoints={[{ maxWidth: 'sm', cols: 1 }, { maxWidth: 'md', cols: 3 }, { maxWidth: 'lg', cols: 3 }]}>
                        <Card className={classes.extraCard} shadow={"xs"} radius={"md"}>
                            <Center>
                                <Card className={classes.innerCard}>
                                    <Image p={0} m={0} width={160} src="./images/home/App_Edit.png" alt="Feature image"  className={classes.image} radius={0} />
                                </Card>
                            </Center>
                            <Card className={classes.infoCard} >
                                <Card p={5} className={classes.innerCard}>
                                    <Center justify="space-between" >
                                        <Text className={classes.bottomTitle} >Customize & Run</Text>
                                    </Center>
                                    <Text size="lg" >
                                        Robomotion Apps are built on a low code platform that lets you to edit applications the way you like. 
                                    </Text>
                                </Card>
                            </Card>
                        </Card>
                        <Card className={classes.extraCard} shadow={"xs"} radius={"md"}>
                            <Center>
                                <Card className={classes.innerCard}>
                                    <Image p={0} m={0} width={160} src="./images/home/App_AI.png" alt="Feature image"  className={classes.image} radius={0} />
                                </Card>
                            </Center>
                            <Card className={classes.infoCard} >
                                <Card p={5} className={classes.innerCard}>
                                    <Center justify="space-between" >
                                        <Text className={classes.bottomTitle} >AI Powered</Text>
                                    </Center>
                                    <Text size="lg" >
                                        Robomotion Apps are integrated with leading AI platforms like Replicate, OpenAI and ElevenLabs
                                    </Text>
                                </Card>
                            </Card>
                        </Card>
                        <Card className={classes.extraCard} shadow={"xs"} radius={"md"}>
                            <Center>
                                <Card className={classes.innerCard}>
                                    <Image p={0} m={0} width={160} src="./images/home/App_Key.png" alt="Feature image"  className={classes.image} radius={0} />
                                </Card>
                            </Center>
                            <Card className={classes.infoCard} >
                                <Card p={5} className={classes.innerCard}>
                                    <Center justify="space-between" >
                                        <Text className={classes.bottomTitle} >Bring Your Own Keys</Text>
                                    </Center>
                                    <Text size="lg" >
                                        Robomotion Apps let you to use your own api keys for third party applications.
                                    </Text>
                                </Card>
                            </Card>
                        </Card>
                    </SimpleGrid>
                </Center>
            </div>
        </Container>

    );
}

export default AppsExtra;

import posthog from 'posthog-js'
import { Text, Button, Paper, Center } from '@mantine/core';
import { IconDownload } from '@tabler/icons';
import colors from '../../const/colors';

const Card = ({ os, icon, version, date, downloads }) => {

	const onDownloadClick = (link, label) => {
		let arch = ""; // Initialize the arch variable
		let os = "";
		let dist = "-";
		// Logic to set the arch variable based on os and label
		if(os === "MacOS" && label.includes("Intel")) {
			os = "mac";
			arch = "intel";
			dist = "-";
		} else if(os === "MacOS" && (label.includes("ARM") || label.includes("M1") || label.includes("M2"))) {
			os = "mac";
			arch = "arm";
			dist = "-";
		} else if(os === "Linux" && label.includes("Ubuntu 22.04")) {
			os = "linux";
			arch = "intel";
			dist = "ubuntu_22";
		} else if(os === "Linux" && label.includes("Ubuntu 20.04")) {
			os = "linux";
			arch = "intel";
			dist = "ubuntu_20";
		} else if(os === "Raspberry Pi") {
			os = "linux";
			arch = "arm";
			dist = "rpi";
		} else if(os === "Windows") {
			arch = "intel";
			os = "windows";
			dist = "-";
		}

		posthog.capture('Download Robot', { os, arch, dist, version })

		window.location.href = link;
	} 

	const downloadButton = (label, link) => {
		return (
		<Center>
			<Text size="lg" color="dimmed" m={10}>
				<Button leftIcon={<IconDownload />} size="xl" radius="md"  onClick={() => onDownloadClick(link, label)} variant="gradient"  gradient={{ from: colors.Primary, to: colors.Primary }}>
					<b>{label}</b>
				</Button>	
			</Text>
		</Center>);
	}

	return (
		<Paper
			shadow="sm"
			radius="sm"
			p="lg"
			m="sm"
			>
			<Center mt={36}>

			<img alt="icon" src={icon} size={24} />

			</Center>
				<Text align="center" size={38} weight={800} mt="lg" color={colors.Primary}>
					{os}
				</Text>
			<Text align="center" size="xl" weight={300} mt="md" pt={20} color={colors.PrimaryText}>
				<b>{version}</b>
			</Text>
			<Text align="center" color="dimmed" weight={300} size="md" mb={10}>
				{date}
			</Text>
				
			{downloads.map(r => downloadButton(r.name, r.link))}
		
		</Paper>

	);
}

export default Card;

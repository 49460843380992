import { Carousel } from '@mantine/carousel';
import { useMediaQuery } from '@mantine/hooks';
import { createStyles, Paper, useMantineTheme, Blockquote, SimpleGrid } from '@mantine/core';
import colors from '../../const/colors';
import { IconInfoCircle } from '@tabler/icons';

const useStyles = createStyles((theme) => ({
  paper: {
    opacity: 1,
    backgroundColor: colors.Transparent,
  },

  quote: {
    color: colors.Primary,
    fontSize: 16,
    fontWeight: 300,
  },
}));

function Card( title, description ) {
  const { classes } = useStyles();
  const icon = <IconInfoCircle />;

  return (
    
    <Paper withBorder  m={10} p="sm" radius="md" className={classes.paper}>
      <div>
        <Blockquote color="red" cite={title} className={classes.quote}>
            {description}
        </Blockquote>
      </div>
    </Paper>
  );
}

const data = [
  {
    title: '– Andy H.',
    description: "I started from zero to now having a few flows that I'm proud to be able to built on my own",
  },
  {
      title: '– SEMsacional',
      description: "I'm doing in one hour more than the team of 14 people who update the website daily. I hope you get some inspiration for your business.",
  },
  {
    title: '– Reinaldo L.',
    description: "The best support I ever seen in almost 30 years of IT. Solid and blazing fast. State of the art of RPA world.",
  },
  {
    title: '– Adam_G',
    description: "Probably the greatest thing about Robomotion is, that in most cases, only your imagination limits its usage potential..",
  },
  {
    title: '– Kris R.',
    description: "Amazing support by the founders and the team on discord channel that helps you resolve issues in real time. "
  },
  {
    title: '– Karston F.',
    description: "This tool is amazing for creating automation. I do a lot of B2B marketing and I was able to use this tool to cut costs significantly in my business.",
  },
  {
    title: '– Darrell S.',
    description: "Go to their discord server and ask questions and they have a full and very active community there to answer your questions and help you solve any issues you are stuck on.",
  },
  {
    title: '– Philip F.',
    description: "I am automating basic bookkeeping, marketing and data scraping workflows. It is a huge timesaver and I ABSOLUTELY LOVE IT! (sorry for my enthusiasm but the tool is really an amazing one)",
  },
  {
    title: '– David B.',
    description: "Amazing! with 10% of the previous cost I can duplicate myself 10 times now!!! The Support from the team is amazing. Join the Discord and you will see for yourself!",
  }
];

const bigData = [
  {
    title: '– Andy H.',
    description: "I started from zero to now having a few flows that I'm proud to be able to built on my own, and use everyday, some of them are: - flow to automatically download bing wallpaper - flow to interact with bank website, helping me transfer money, and download bank statement each month - flow to monitor my competitors in Google maps - flow to help me register to weekly events (from Whatsapp!!)",
  },
  {
      title: '– SEMsacional',
      description: "I created 6 bots to get provider's prices and stocks from 6 vendors, and i merge them in a single CSV file, leaving the cheaper products that are available to purchase (with stock). I deliver this 4 times per day to my client. He uses this feed to update their own prices and stocks on their website. It's not easy, as they have 59.000 products, and some are on stock and some are dropshipping, but... I'm doing in one hour more than the team of 14 people who update the website daily. I hope you get some inspiration for your business.",
  },
  {
    title: '– Reinaldo L.',
    description: "The best support I ever seen in almost 30 years of IT. I wrote a review about this amazing tool before. Solid and blazing fast. State of the art of RPA world. I'm writing this review because of the support of Robomotion. Faik and his team are superb. Through the Discord channel I always, always had a answer in a question of minutes. ",
  },
  {
    title: '– Adam_G',
    description: "Fantastic product with great support. There's a learning curve involved if you wanted to use it yourself, but the support you'll get should be enough to create basic actions. Probably the greatest thing about Robomotion is, that in most cases, only your imagination limits its usage potential..",
  },
  {
    title: '– Julia D.',
    description: "This Software Has Changed My Life. The Robomotion team is consistently there to support their users. Whether it's designing an automation flow, troubleshooting errors, or improving the software for simpler, dependable use, they're on it. I've always found the help I needed, and this team has effectively solved all of my problems. No doubt the best support team I have ever works with!",
  },
  {
    title: '– Kris R.',
    description: "Amazing support by the founders and the team on discord channel that helps you resolve issues in real time. Near perfect scheduling Ability to install on Linux/Mac and Windows Super fast bug fixes and exceptional support to help scale and migrate processes."
  },
  {
    title: '– Karston F.',
    description: "This tool is amazing for creating automation. I do a lot of B2B marketing and I was able to use this tool to cut costs significantly in my business.",
  },
  {
    title: '– Darrell S.',
    description: "I am not a developer, so a low code solution was what I needed for my RPA software. No-code solutions were too limiting, so this gave me what I need. I am using this software to do some web scraping, and since I have no experience...I was very happy to have a tutorial for me to go through...if that wasn't specific enough, then I can go to their discord server and ask questions and they have a full and very active community there to answer your questions and help you solve any issues you are stuck on.",
  },
  {
    title: '– Philip F.',
    description: "I am automating basic bookkeeping, marketing and data scraping workflows. It is a huge timesaver and I ABSOLUTELY LOVE IT! (sorry for my enthusiasm but the tool is really an amazing one)",
  },
  {
    title: '– David B.',
    description: "Amazing! with 10% of the previous cost I can duplicate myself 10 times now!!! The Support from the team is amazing, I am sure I could not have leveraged it so well by myself but the [sensitive content hidden] and the team was Amazing in supporting me, Join the Discord and you will see for yourself!",
  }
];



const Testimonials = () => {
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const slides = data.map((item) => (
      Card(item.title, item.description)
  ));

  return (
      <SimpleGrid spacing={5} cols={3} breakpoints={[{ maxWidth: 'sm', cols: 1 }, { maxWidth: 'md', cols: 2 }, { maxWidth: 'lg', cols: 3 }]}>
      {slides}
      </SimpleGrid>
      
  );
}

export default Testimonials;
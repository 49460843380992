import {
    createStyles,
    Title,
    Text,
    Container,
    Button,
    Center,
} from '@mantine/core';
import colors from '../../const/colors';
import robot from './robot';
import { IconDownload } from '@tabler/icons';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin:"auto",
        paddingTop: 80,

        '@media (max-width: 755px)': {
            paddingTop: 80,
            paddingBottom: 60,
        },
    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 80,
    },

    title: {
        textAlign: 'center',
        fontWeight: 900,
        fontSize: "3.5em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        marginBottom: theme.spacing.xl,
        marginTop: theme.spacing.md,
        fontFamily: 'Inter',

        [theme.fn.smallerThan('lg')]: {
            fontSize: "2.8em",
        },
    },

    description: {
        color: colors.Primary,
        textAlign: 'center',
        fontSize: 30,
        maxWidth: "900px",
        fontFamily: 'Barlow',
        
    },

    underlined: {
        textDecoration: "underline wavy #2196F3"
    },

    subDescription: {
        marginTop: "40px",
        textAlign: 'center',
        fontSize: 16,
    },

    ratingBase: {
        textAlign: 'center',
        fontSize: 18,
        maxWidth: "300px",
        fontWeight: "400",
        marginTop: "-10px",
        color: colors.PrimaryText,
    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenSM: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

}));

const detectOS = () => {
    var OS="windows";

    if (typeof(navigator) === 'undefined') return OS;
    if (navigator.userAgent.indexOf("Win")!==-1) OS="windows";
    if (navigator.userAgent.indexOf("Mac")!==-1) OS="macos";
    if (navigator.userAgent.indexOf("X11")!==-1) OS="linux";
    if (navigator.userAgent.indexOf("Linux")!==-1) OS="linux";
    return OS;
}


const Hero = () => {

    const { classes } = useStyles();
    const os = detectOS();

    return (
        <Container className={classes.wrapper} size={1200}>
            <div className={classes.hero}>
                <Title className={classes.title}>
                    <Text component="span"
                        className={classes.highlight}
                        inherit
                        variant="gradient"
                        gradient={{ from: colors.Secondary, to: colors.Secondary }}
                    >
                    Robomotion </Text>{''} Installer
                    
                </Title>

                <Container p={0} size={1100}>
                    <Center>
                        <Text className={classes.description}>
                            Installer contains Robomotion <b>Desktop Application</b>, <b>Software Robot</b> and an <b>Agent Software</b> to interact with your machine.
                        </Text>
                    </Center>
                </Container>

                <Container p={0} size={800} className={classes.subDescription}>
                    <Text size="lg" color="dimmed" >
                        <Button leftIcon={<IconDownload />} size="xl" radius="sm"  onClick={() => {window.location.href = robot[os].downloads[0].link}} variant="gradient"  gradient={{ from: colors.Secondary, to: colors.Secondary }}>
                            <b>Download for {robot[os].os}</b>
                        </Button>
                    </Text>
                    <Text size="xl" color="">
                        <div><p>{robot[os].version}</p></div>
                    </Text>

                </Container>
            </div>
        </Container>

    );
}

export default Hero;

import {
    createStyles,
    Title,
    Text,
    Container,
    Image,
    Button,
    Center,
    Rating,
    Mark,
    Group,
    Stack,
} from '@mantine/core';

import colors from '../../../const/colors';
import { IconArrowRight } from '@tabler/icons';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin:"auto",
        paddingTop: 0,
        backgroundColor:colors.PrimaryBackground,

    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 40,
    },

    title: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "3.5em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    subTitle: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "2em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    description: {
        color: colors.Primary,
        textAlign: 'center',
        fontSize: 24,
        maxWidth: "900px",
        fontFamily: 'Barlow',
        
    },

    highlight_1: {
        display: "inline-block",
        background: colors.Camboge,
        color: colors.Info
    },

    underlined: {
        textDecoration: "underline wavy #2196F3"
    },

    subBody: {
        marginTop: "40px",
        textAlign: 'center',
        fontSize: 20,
    },

    mainCard: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: "400",
        backgroundColor: colors.Transparent,
    },

    appCard: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: "400",
        backgroundColor: colors.Transparent,
    },

    appName: {
        fontFamily: 'Barlow',
        fontSize: 22,
    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenSM: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },
    highlight: {
        textDecoration: 'underlined'
    }

}));

const Hero = () => {

    const { classes } = useStyles();

    return (
        <Container className={classes.wrapper} size={1200}>
            <div className={classes.hero}>
                <Title className={classes.title}>             
                    <Text component="span"
                        className={classes.highlight}
                        inherit
                        variant="gradient"
                        gradient={{ from: colors.Secondary, to: colors.Secondary }}
                    >
                    {' '}Apps</Text>{' '}For Users
                </Title>

                <Container pt={10}>
                    <Center>
                        <Text className={classes.description}>
                        Fully functional, <Text component="span" inherit fw={600}><Mark p={5}>customizable</Mark></Text> apps built with Robomotion. We encourage customization of our apps by sharing the complete <Text component="span" inherit fw={600}><Mark p={5}>Robomotion Flow</Mark></Text> backend and the <Text component="span" inherit fw={600}><Mark p={5}>FlutterFlow</Mark></Text> frontend.

                        </Text>
                    </Center>
                </Container>

            </div>
        </Container>

    );
}

export default Hero;

import { Avatar, Text,  Paper, Center, createStyles } from '@mantine/core';
import colors from '../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        position: 'relative',
        margin: "auto",
        display: 'flex',
        paddingTop: 80,

        '@media (max-width: 755px)': {
            paddingTop: 80,
        },
    },

	avatar: {
		border: "4px solid #FFFFFF",
		shadowColor: colors.RichBlack,
		shadowOpacity: 0.58,
		shadowRadius: 16.00,
	},

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 80,
    },

    title: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: 42,
        lineHeight: "1.2",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        marginBottom: theme.spacing.xl,
        [theme.fn.smallerThan('lg')]: {
            fontSize: "3em",
        },
    },
    
    subTitle: {
        textAlign: 'center',
        fontWeight: 300,
        fontSize: 32,
        lineHeight: "1.2",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        marginBottom: theme.spacing.xl,
    },
    description: {
        color: colors.PrimaryText,
        textAlign: 'center',
        letterSpacing: "-0.02em",
        lineHeight: "1.4",
        fontSize: 24,
    },

    subDescription: {
        marginTop: "80px",
        textAlign: 'center',
        fontSize: 16,
    },


    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

    img: {
        maxWidth: 650,
    },

}));

const TeamCard = ({ id, avatar, name, linkedin, role }) => {

	const { classes } = useStyles();

	return (
		<Paper
			p="lg"
			sx={(theme) => ({
				backgroundColor: colors.Transparent,
			})}
			>
			<Avatar src={avatar} size={120} radius={120} mx="auto" className={classes.avatar}/>
				<Text align="center" size="xl" weight={500} mt="md">
					{name}
				</Text>
			<Center>
                {/*
				<ActionIcon  radius="xl" mx={3} component="a" href={linkedin} color="blue">
					<IconBrandLinkedin size={26} stroke={1.5} />
				</ActionIcon>
                */}   
			</Center>

			<Text align="center" size="md">
				{role}
			</Text>
		</Paper>
	
	);
}

export default TeamCard;

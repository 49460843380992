import {
    createStyles,
    Text,
    Container,
    Card,
    Image,
} from '@mantine/core';
import remarkGfm from 'remark-gfm';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

const ParagraphExtra = (props) => <Text size="md" mt={4}>{props.children}</Text>
//const ImageExtra = (props) => <img src={props.src} style={{ maxWidth: "90%", textAlign: "center", margin:"auto"}}  />
const ImageWithcard = (props) => 
<Card shadow="xs" m={20} p={30} radius="md" >
    <Card p={0} m={0} radius="lg" >
        <Image fit="contain" src={props.src} alt="Robomotion Blog"></Image>
    </Card>
</Card>


const useStyles = createStyles((theme) => ({
    wrapper: {
        margin: "auto",
        paddingTop: 80,

        '@media (max-width: 755px)': {
            paddingTop: 80,
        },
    },

    hero: {
        position: 'relative',
        zIndex: 1,
    },

    
}));



const Body = ({content}) => {
    const { classes } = useStyles();
    return (
        <Container className={classes.wrapper} size={800} pb={100}>
            <div className={classes.hero}>
                <ReactMarkdown children={content} remarkPlugins={[remarkGfm]} components={{ p: ParagraphExtra, img: ImageWithcard }} />
            </div>
        </Container>
    );
}

export default Body;

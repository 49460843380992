import {
    createStyles,
    Title,
    Text,
    Card,
    Container,
    AspectRatio,
    Center,
} from '@mantine/core';

import colors from '../../../const/colors';

const useStyles = createStyles((theme) => ({


}));

const UserBase = () => {

    const { classes } = useStyles();

    return (


        <Container size={1100}>
            <Card shadow={"lg"}>
                <AspectRatio ratio={1920 / 1080} maw={1180} mx="auto">
                        <video src="https://apps.robomotion.io/videos/robomotion-apps.mp4" autoPlay muted loop >
                        </video>
                </AspectRatio>
            </Card>
        </Container>

    );
}

export default UserBase;

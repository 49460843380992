import plans from './plans' 
import { IconCheck, IconCircleCheck } from '@tabler/icons';
import { Space, Text, List, Flex, Button, Paper, ThemeIcon, Center, Group, createStyles } from '@mantine/core';
import colors from '../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin:"auto",
        paddingTop: 80,

        '@media (max-width: 755px)': {
            paddingTop: 80,
            paddingBottom: 60,
        },
    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 80,
    },

    title: {
        textAlign: 'center',
        fontWeight: 800,
        fontSize: 20,
        color: colors.Accent1,
        marginTop: 5,

        [theme.fn.smallerThan('lg')]: {
            fontSize: 16,
        },
        fontFamily: "Barlow",

    },

    price: {
        color: colors.Primary,
        textAlign: 'center',
        lineHeight: "1.4",
        fontSize: 50,
        fontWeight: 700,
        fontFamily: "Barlow",

    },

    description: {
        color: colors.PrimaryText,
        textAlign: 'center',
        letterSpacing: "-0.02em",
        lineHeight: "1.4",
        fontSize: 24,
        marginTop:60,
        fontFamily: "Barlow",

    },

    subDescription: {
        marginTop: "20px",
        textAlign: 'center',
        fontSize: 16,
    },

    card :  {
        border: "3px solid",
        borderColor: "#e76f51",
        fontFamily: "Barlow",
    },


/*

    PrussianBlue: "#264653",
    Claret: "#2a9d8f",
    Camboge: "#e9c46a",
    Timberwolf: "#f4a261",
    LitOrange: "#e76f51",
    RichBlack: "#141313",
*/ 

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

}));


const Card = ({plan}) => {
    const { classes } = useStyles();

    const promoted_devel = "Pro"
    const promoted_starter = "Growth"
	const { title, cta, price, description, features, pricingType } = plans[plan];
    /*
	const bg_color = plan === "growth" ?  "bg-[#FF7064]" : "bg-slate-200";
	const text_color = plan === "growth" ?  "text-white" : "text-slate-600";
	const billing_period = plan !== "enterprise" ? "per month" : ".";
	const color_period = plan !== "enterprise" ? "text-slate-500" : "text-white";
    */
	const buy_link = plan !== "enterprise" ? `https://app.robomotion.io/create?plan=${plan}` : "mailto:sales@robomotion.io";
    const buttonColor = (title === promoted_devel || title === promoted_starter) ? { from: colors.Secondary, to: colors.Secondary } : { from: colors.Primary, to: colors.Primary } ;

	return (
		<Paper shadow={(title === promoted_devel || title === promoted_starter) ? "md": "xs"} radius="md"  pl="md" pr="md" pb="md" className={(title === promoted_devel || title === promoted_starter) ? classes.card: ""}>
            <Flex
                mih={(title === promoted_devel || title === promoted_starter) ? 750 : 750}
                justify="flex-top"
                align="center"
                direction="column"
                wrap="wrap"
            >
                <Text align="center" weight={600} className={classes.title} pt={20}>
                    {title}
                </Text>
                <Text align="center" size="md" className={classes.price} pt={36}>
                    {price}
                </Text>
                <Text align="center" color="dimmed" weight={300} size="sm">
                    {pricingType}
                </Text>
                <Text align="center"  size="xl" p={20}>
                    {description}
                </Text>
                <Center>
                    <List
                        spacing="xs"
                        size={15}
                        p={20}
                        left
                        icon={<IconCheck size={20} />}
                        >
                        <List.Item>{features[0]}</List.Item>
                        <List.Item>{features[1]}</List.Item>
                        <List.Item>{features[2]}</List.Item>
                        {features[3] ? <List.Item>{features[3]}</List.Item> : <></>}
                        {features[4] ? <List.Item>{features[4]}</List.Item> : <></>}
                        {features[5] ? <List.Item>{features[5]}</List.Item> : <></>}
                        {features[6] ? <List.Item>{features[6]}</List.Item> : <></>}
                        {features[7] ? <List.Item>{features[7]}</List.Item> : <></>}
                        {features[8] ? <List.Item>{features[8]}</List.Item> : <></>}
                        {features[9] ? <List.Item>{features[9]}</List.Item> : <></>}
                        {features[10] ? <List.Item>{features[10]}</List.Item> : <></>}
                        {features[11] ? <List.Item>{features[11]}</List.Item> : <></>}
                    </List>
                </Center>
            </Flex>
            <Flex
                justify="flex-end"
                align="center"
                direction="column"
                wrap="wrap"
            >
                <Center>
                    <Text size="xl" color="dimmed" m={10}> 
                        <Button leftIcon={<IconCheck />} size="lg" radius="md"  onClick={() => {window.location.href = buy_link}} variant="gradient" gradient={buttonColor}>
                            {cta}
                        </Button>	
                    </Text>
                </Center>
            </Flex>
		</Paper>
	);
}

export default Card;
import { Avatar, Text, Paper, Card, ActionIcon, Center } from '@mantine/core';
import { IconBrandLinkedin } from '@tabler/icons';
import colors from '../../../const/colors';


const ExpertCard = ({ avatar, name, linkedin, offer, languages }) => {
	return (
		<Card
			shadow="sm"
			radius="md"
			p="xl"
			sx={(theme) => ({
				backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
			})}
			>
			<Avatar src={avatar} size={120} radius={120} mx="auto" />
				<Text align="center" size="xl" weight={500} mt="md">
					{name}
				</Text>
			<Center>
				<ActionIcon  radius="xl" mx={3} component="a" href={linkedin} color="blue">
					<IconBrandLinkedin size={26} stroke={1.5} />
				</ActionIcon>
			  </Center>
			<Text align="left" size="lg" color={colors.PrimaryText} weight={600} mt="md">
				OFFER
			</Text>
			<Text align="left" >
				{offer}
			</Text>
			<Text align="left" size="lg" color={colors.PrimaryText}  weight={600} mt="md">
				LANGUAGES
			</Text>
			<Text align="left" >
				{languages}
			</Text>

		</Card>
	
	);
}

export default ExpertCard;

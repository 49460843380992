import { useState } from 'react';
import { Slider, Text, Container } from '@mantine/core';

const EarningCalculator = () => {
  const [value, setValue] = useState(25);
  const [endValue, setEndValue] = useState(25); // eslint-disable-line

  return (
    <Container size={400}>
	  <Text m="md" size="xl">
       <b>{value} </b> Referrals - <Text component="span" m="md" size="xl" fw={700} variant="gradient" gradient={{ from: 'green', to: 'green' }}>${(parseFloat(value) * 159 * 0.4).toFixed(1)}</Text> / per month
      </Text>
      <Slider value={value} onChange={setValue} onChangeEnd={setEndValue} size={"xl"} color={"green"}/>
    </Container>
  );
}

export default EarningCalculator;

const webinars = [
    {
        image: "/images/webinar/robomotion-august-2023-updates.jpg",
        slug: "robomotion-august-2023-updates",
        url: "/webinar/robomotion-august-2023-updates",
        title: "Robomotion August 2023 Monthly Update",
        link: "/webinar/robomotion-august-2023-updates",
        youtube: "kiClq9BCS7Y",
        text: "",
        dates: "20230831T180000Z/20230831T180000Z",
        date: "August 27, 2023",
        time: "18:00 GMT",
    },
    {
        image: "/images/webinar/robomotion-july-2023-updates.jpg",
        slug: "robomotion-july-2023-updates",
        url: "/webinar/robomotion-july-2023-updates",
        title: "Robomotion July 2023 Monthly Update",
        link: "/webinar/robomotion-july-2023-updates",
        youtube: "V6KRQ6l5tVE",
        text: "",
        dates: "20230627T180000Z/20230627T180000Z",
        date: "July 27, 2023",
        time: "18:00 GMT",
    },
    {
        image: "/images/webinar/robomotion-content-marketing-webinar.png",
        slug: "content-marketing-with-robomotion",
        url: "/webinar/content-marketing-with-robomotion",
        title: "Content Marketing with Robomotion",
        link: "/webinar/content-marketing-with-robomotion",
        youtube: "xgcLf8g3Nc4",
        text: "",
        dates: "20220405T150000Z/20220405T160000Z",
        date: "April 5, 2022",
        time: "18:00 GMT",
    },
    {
        image: "/images/webinar/robomotion-crypto-automation-webinar.png",
        slug: "crypto-bots-with-robomotion",
        url: "/webinar/crypto-bots-with-robomotion",
        title: "Crypto Bots with Robomotion",
        link: "/webinar/crypto-bots-with-robomotion",
        youtube: "x7CxD-5rQ4o",
        text: "",
        dates: "20220228T130000Z/20220228T140000Z",
        date: "February 28, 2022",
        time: "4:00 PM GMT",
    },
    {
        image: "/images/webinar/robomotion-parallel-processing-webinar.png",
        slug: "parallel-processing-with-robomotion",
        url: "/webinar/parallel-processing-with-robomotion",
        title: "Parallel Processing With Robomotion",
        link: "/webinar/parallel-processing-with-robomotion",
        youtube: "d5BWXZEp5Uo",
        text: "",
        dates: "20220126T130000Z/20220126T140000Z",
        date: "January 26, 2022",
        time: "4:00 PM GMT",
    },
    {
        image: "/images/webinar/robomotion-web-scraping-webinar.jpg",
        slug: "web-scraping-with-robomotion",
        url: "/webinar/web-scraping-with-robomotion",
        title: "Web Scraping with Robomotion",
        link: "/webinar/web-scraping-with-robomotion",
        youtube: "kndVY-jVDM4",
        text: "",
        dates: "20211216T140000Z/20211216T150000Z",
        date: "December 16, 2021",
        time: "5:00 PM GMT",
    },
    {
        image: "/images/webinar/robomotion-whatsapp-automation-webinar.jpg",
        slug: "whatsapp-automation-with-rpa",
        url: "/webinar/whatsapp-automation-with-rpa",
        title: "WhatsApp Automation with RPA",
        link: "/webinar/whatsapp-automation-with-rpa",
        youtube: "bNPKKAmSnUU",
        text: "",
        dates: "20210428T150000Z/20210428T160000Z",
        date: "April 28, 2021",
        time: "3:00 PM GMT",
    },
    {
        image: "/images/webinar/robomotion-growth-marketing-webinar.jpg",
        slug: "growth-marketing-with-rpa",
        url: "/webinar/growth-marketing-with-rpa",
        title: "Growth Marketing with RPA",
        link: "/webinar/growth-marketing-with-rpa",
        youtube: "ub2a4anC2Hc",
        text: "",
        dates: "20210331T150000Z/20210331T160000Z",
        date: "March 31, 2021",
        time: "3:00 PM GMT",
    },
];

export default webinars;

import React, { useState } from 'react';
import { Container, TextInput, Button, Text, Title, Group, Card, Divider } from '@mantine/core';

const ROICalculator = () => {
  const [inputs, setInputs] = useState({
    employeeHourlyRate: '',
    numberOfEmployees: '',
    hoursSpentPerMonth: '',
    developmentCost: '',
    softwareLicenseCost: '',
    timeSavings: '',
    operationalTime: '',
  });

  const [roiDetails, setRoiDetails] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const calculateROI = () => {
    const {
      employeeHourlyRate,
      numberOfEmployees,
      hoursSpentPerMonth,
      developmentCost,
      softwareLicenseCost,
      timeSavings,
      operationalTime,
    } = inputs;

    const timeSavingsFactor = timeSavings < 100 ? timeSavings / 100 : 1;
    const currentProcessCost = employeeHourlyRate * numberOfEmployees * hoursSpentPerMonth * operationalTime * timeSavingsFactor;
    const totalRPAImplementationCost = parseFloat(developmentCost) + parseFloat(softwareLicenseCost);

    const netSavings = currentProcessCost - totalRPAImplementationCost;
    const roiValue = (netSavings / totalRPAImplementationCost) * 100;

    setRoiDetails({
      roi: roiValue.toFixed(2),
      currentProcessCost: currentProcessCost.toFixed(2),
      totalRPAImplementationCost: totalRPAImplementationCost.toFixed(2),
      netSavings: netSavings.toFixed(2),
    });
  };

  return (
    <Container size={400} my={40}>
      <Title order={2} align="center" mb="lg">
        RPA ROI Calculator
      </Title>
      <form>
        <TextInput
          label="Employee Hourly Rate ($)"
          description="The average hourly wage of employees performing the manual task."
          placeholder="e.g., 20"
          name="employeeHourlyRate"
          value={inputs.employeeHourlyRate}
          onChange={handleChange}
          required
          mb="md"
        />
        <TextInput
          label="Number of Employees"
          description="The number of employees involved in the manual process."
          placeholder="e.g., 3"
          name="numberOfEmployees"
          value={inputs.numberOfEmployees}
          onChange={handleChange}
          required
          mb="md"
        />
        <TextInput
          label="Total Hours Spent per Month per Employee"
          description="Total hours spent by each employee on the manual task per month."
          placeholder="e.g., 60"
          name="hoursSpentPerMonth"
          value={inputs.hoursSpentPerMonth}
          onChange={handleChange}
          required
          mb="md"
        />
        <TextInput
          label="Development Cost ($)"
          description="Cost to develop and deploy the RPA solution."
          placeholder="e.g., 4500"
          name="developmentCost"
          value={inputs.developmentCost}
          onChange={handleChange}
          required
          mb="md"
        />
        <TextInput
          label="Software License Cost per Year ($)"
          description="Cost of RPA software licenses per year."
          placeholder="e.g., 6000"
          name="softwareLicenseCost"
          value={inputs.softwareLicenseCost}
          onChange={handleChange}
          required
          mb="md"
        />
        <TextInput
          label="Time Savings (%)"
          description="Percentage of time saved by automating the process."
          placeholder="e.g., 100"
          name="timeSavings"
          value={inputs.timeSavings}
          onChange={handleChange}
          required
          mb="md"
        />
        <TextInput
          label="Operational Time (months)"
          description="Duration for which the RPA solution will be operational in months."
          placeholder="e.g., 12"
          name="operationalTime"
          value={inputs.operationalTime}
          onChange={handleChange}
          required
          mb="md"
        />
        <Group position="center" mt="md">
          <Button onClick={calculateROI}>Calculate ROI</Button>
        </Group>
      </form>
      {roiDetails && (
        <Card shadow="sm" padding="lg" mt="lg">
          <Title order={3} align="center" mb="md">
            ROI Details
          </Title>
          <Divider my="sm" />
          <Text align="center" size="lg" weight={600}>
            {roiDetails.roi}%
          </Text>
          <Divider my="sm" />
          <Text>
            <strong>Current Process Cost:</strong> ${roiDetails.currentProcessCost}
          </Text>
          <Text>
            <strong>Total RPA Implementation Cost:</strong> ${roiDetails.totalRPAImplementationCost}
          </Text>
          <Text>
            <strong>Net Savings:</strong> ${roiDetails.netSavings}
          </Text>
        </Card>
      )}
    </Container>
  );
};

export default ROICalculator;

import Users from '../shared/Users';
import Discord from '../shared/Discord';
import Webinar from './Webinar';
import { useParams } from "react-router-dom";

const Webinars = () => {
    const { slug } = useParams();

    return (
        <>
            <Webinar slug={slug} />
            <Users />
            <Discord />
        </>
    );
}

export default Webinars;
import {
    createStyles,
    Title,
    Text,
    Container,
    SimpleGrid,
    Card,
    Center
} from '@mantine/core';

import colors from '../../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin:"auto",
        paddingTop: 80,

        '@media (max-width: 755px)': {
            paddingTop: 80,
            paddingBottom: 60,
        },
    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 80,
    },

    title: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: "4.2em",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        marginBottom: theme.spacing.sm,
        marginTop: theme.spacing.md,
        fontFamily: 'Barlow',

        [theme.fn.smallerThan('lg')]: {
            fontSize: "3em",
        },
    },

    subTitle: {
        textAlign: 'center',
        fontSize: "1.8em",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',

        [theme.fn.smallerThan('lg')]: {
            fontSize: "3em",
        },
    },

    description: {
        color: colors.PrimaryText,
        textAlign: 'center',
        fontSize: 30,
        maxWidth: "800px",
        lineHeight: 1.7,
        fontFamily: 'Barlow',

    },

    underlined: {
        textDecoration: "underline wavy #2196F3"
    },

    subDescription: {
        marginTop: "40px",
        textAlign: 'center',
        fontSize: 16,
    },

    ratingBase: {
        textAlign: 'center',
        fontSize: 18,
        maxWidth: "300px",
        fontWeight: "400",
        marginTop: "-10px",
        color: colors.PrimaryText,
    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenSM: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

    card: {
        maxWidth: "500px",
		backgroundColor: colors.Primary,
	},
}));

const PartnerTypes = () => {
    const { classes } = useStyles();

    return (
         <Container className={classes.wrapper} size={"%100"} p={0} m={0}>
            <div className={classes.hero}>
                <Title className={classes.title}>
                    Who Are Enterprise {' '}
                    <Text component="span"
                        className={classes.highlight}
                        inherit
                        variant="gradient"
                        gradient={{ from: colors.Secondary, to: colors.Secondary }}
                    >
                        Partners?&nbsp;
                    </Text>{''}
                    
                </Title>

                <Container px={30} size={800}>
                    <Center>
                        <Text size="lg" className={classes.subTitle}>
                            Join forces with Robomotion to help customers unleash the power of Robotic Process Automation.
                        </Text>
                    </Center>
                </Container>
                <Container pt={20} pb={20} size={1100}>
                    <Center>
                        <Container className={classes.wrapper} size={1100}>
                            <SimpleGrid cols={4} breakpoints={[{ maxWidth: 'sm', cols: 1 }, { maxWidth: 'md', cols: 2 }]}>
                            <Card className={classes.card} withBorder radius="md">
                                <Center style={{ height: 100, color: colors.WhiteText, fontWeight:"500" }} fz={20}>
                                    RPA Consultants
                                </Center>
                            </Card>
                            <Card className={classes.card} withBorder radius="md">
                                <Center style={{ height: 100, color: colors.WhiteText, fontWeight:"500" }} fz={20}>
                                    System Integrators
                                </Center>
                            </Card>
                            <Card className={classes.card} withBorder radius="md">
                                <Center style={{ height: 100, color: colors.WhiteText, fontWeight:"500" }} fz={20}>
                                    Industry Experts
                                </Center>
                            </Card>
                            <Card className={classes.card} withBorder radius="md">
                                <Center style={{ height: 100, color: colors.WhiteText, fontWeight:"500" }} fz={20}>
                                Software Developers
                                </Center>
                            </Card>
                            </SimpleGrid>
                        </Container>
                    </Center>
                </Container>

            </div>
        </Container>
    );
}

export default PartnerTypes;

import { Helmet } from 'react-helmet';
import TeamHero from './TeamHero';
import TeamList from './TeamList';
import TeamInvestors from './TeamInvestors';
import Discord from '../shared/Discord';

const AboutHome = () => {
    const title = "About - Robomotion RPA";
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta name="twitter:title" content={title} />
            </Helmet>        
            <TeamHero />
            <TeamList />
            <TeamInvestors />
            <Discord />
        </>
    );
}

export default AboutHome;

import { Center, Container, SimpleGrid, Text, Title, createStyles, Mark, Button, Card, AspectRatio } from '@mantine/core';
import data from './data.jsx'
import colors from '../../../const/colors.jsx';
import FeatureView from './FeatureView'
import React, {useState} from 'react';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin:"auto",
        paddingTop: 0,
        backgroundColor:colors.PrimaryBackground,

    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 80,
    },

    title: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "3.5em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    subTitle: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "2em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    description: {
        color: colors.Primary,
        textAlign: 'center',
        fontSize: 24,
        maxWidth: "900px",
        fontFamily: 'Barlow',
        
    },

    highlight_1: {
        display: "inline-block",
        background: colors.Camboge,
        color: colors.Info
    },

    underlined: {
        textDecoration: "underline wavy #2196F3"
    },

    subBody: {
        marginTop: "40px",
        textAlign: 'center',
        fontSize: 20,
    },

    mainCard: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: "400",
        backgroundColor: colors.Transparent,
    },

    appCard: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: "400",
        backgroundColor: colors.Transparent,
    },

    appName: {
        fontFamily: 'Barlow',
        fontSize: 22,
    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenSM: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },
    highlight: {
        textDecoration: 'underlined'
    }


}));

const Features = () => {

    const { classes } = useStyles();

    const features = data.map((feature) => (
        FeatureView(feature) 
    ));



    const [value = 0, setValue] = useState();
    const feature = value < data.length ? FeatureView(data[value]) : null;


    return (
        <>
            <Container className={classes.wrapper} size={"%100"} pt={120} pb={0}>
                <div className={classes.hero}>
                        <Title className={classes.title}>
                            <Text component="span"
                                className={classes.highlight}
                                inherit
                                variant="gradient"
                                gradient={{ from: colors.Secondary, to: colors.Secondary }}
                            >
                            {' '}<b>Tools</b></Text>{' '}
                            for Developers
                        </Title>


                        <Container p={10} pb={60}>
                            <Center>
                                <Text className={classes.description}>
                                    Robomotion is a <b>low-code </b>, <b>web-based</b> and <b>cloud-native</b> Robotic Process Automation (RPA) platform. RPA is an emerging form of business process automation technology based on the notion of software robots and artificial intelligence.
                                </Text>
                            </Center>
                        </Container>

                        <Center>
                            <SimpleGrid cols={6} spacing={20} breakpoints={[{ maxWidth: 'sm', cols: 3 }, { maxWidth: 'md', cols: 3 }, { maxWidth: 'lg', cols: 3 }]}>
                                <Button size='sm' radius={10} onClick={() => setValue(0)} variant={value == 0 ? 'gradient' : 'outline'} color='gray' gradient={{ from: colors.Secondary, to: colors.Secondary }}>Concurrency</Button>
                                <Button size='sm' radius={10} onClick={() => setValue(1)} variant={value == 1 ? 'gradient' : 'outline'} color='gray' gradient={{ from: colors.Secondary, to: colors.Secondary }}>Engagement</Button>
                                <Button size='sm' radius={10} onClick={() => setValue(2)} variant={value == 2 ? 'gradient' : 'outline'} color='gray' gradient={{ from: colors.Secondary, to: colors.Secondary }}>Local Run</Button>
                                <Button size='sm' radius={10} onClick={() => setValue(3)} variant={value == 3 ? 'gradient' : 'outline'} color='gray' gradient={{ from: colors.Secondary, to: colors.Secondary }}>Cloud Run</Button>
                                <Button size='sm' radius={10} onClick={() => setValue(4)} variant={value == 4 ? 'gradient' : 'outline'} color='gray' gradient={{ from: colors.Secondary, to: colors.Secondary }}>Flow Designer</Button>
                                <Button size='sm' radius={10} onClick={() => setValue(5)} variant={value == 5 ? 'gradient' : 'outline'} color='gray' gradient={{ from: colors.Secondary, to: colors.Secondary }}>Admin Console</Button>
                            </SimpleGrid>
                        </Center>

                        <Center>
                            <SimpleGrid cols={1} spacing={100} breakpoints={[{ maxWidth: 'sm', cols: 1 }, { maxWidth: 'md', cols: 1 }, { maxWidth: 'lg', cols: 1 }]}>
                                {feature}
                            </SimpleGrid>
                        </Center>


                    </div>
            </Container>
        </>
    );
}

export default Features;

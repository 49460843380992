import {
    createStyles,
    Title,
    Text,
    Container,
    Card,
    Center,
    SimpleGrid,
} from '@mantine/core';

import colors from '../../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin:"auto",
        backgroundColor: colors.PrimaryBackground,
    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 50,
    },

    title: {
        textAlign: 'center',
        fontWeight: 500,
        fontSize: "34px",
        color: colors.PrimaryText,
        marginBottom: theme.spacing.sm,
        fontFamily: 'Barlow',
        maxWidth: "750px",

        [theme.fn.smallerThan('lg')]: {
            fontSize: 26,
        },
    },

    description: {
        color: colors.Primary,
        textAlign: 'center',
        fontSize: 22,
        maxWidth: "740px",
    },

    highlight: {
        fontWeight: 800,

    },
    underlined: {
        textDecoration: "underline wavy #2196F3"
    },

    subDescription: {
        marginTop: "40px",
        textAlign: 'center',
        fontSize: 16,
    },

    mainCard: {

    },


    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

}));

const HowItworks = () => {

    const { classes } = useStyles();

    return (
        <Container className={classes.wrapper} size={1200}>
            <div className={classes.hero}>
                <Center>

                <Card shadow={"sm"} className={classes.mainCard} p={50} >
                        <Center>
                            <Title className={classes.title}> 
                                How Does It Work?
                            </Title>
                        </Center>

                        <Container p={16} size={1100}>
                            <Center>
                                <Text className={classes.description}>
                                <b>1. Apply:</b> Complete the application form and become an affiliate partner. We will share the details to create your unique link.
                                </Text>
                            </Center>
                            <Center pt={32}>
                                <Text className={classes.description}>
                                <b>2. Promote:</b> Once you have your unique link. Start promoting Robomotion with your unique link to your audience.
                                </Text>
                            </Center>
                            <Center pt={32}>
                                <Text className={classes.description}>
                                <b>3. Earn Money:</b> If anyone becomes a customer within 60 days of clicking your link, you will start receiving a <b>40%</b> commission on every monthly or yearly payment of the customer's subscription.
                                </Text>
                            </Center>

                        </Container>
                    </Card>
                    </Center>
            </div>
        </Container>

    );
}

export default HowItworks;

import Card from './Card';
import robot from './robot';

import {
    createStyles,
    Title,
    Container,
    SimpleGrid,
} from '@mantine/core';
import colors from '../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin: "auto",
        paddingBottom: 40,
        paddingTop: 10,

    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 20,
    },

    title: {
        textAlign: 'center',
        fontWeight: 300,
        fontSize: "2.5em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    subTitle: {
        textAlign: 'center',
        fontWeight: 300,
        fontSize: "2em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    description: {
        color: colors.Primary,
        textAlign: 'center',
        fontSize: 30,
        maxWidth: "800px",
        fontFamily: 'Barlow',
        
    },
    subDescription: {
        marginTop: "80px",
        textAlign: 'center',
        fontSize: 16,
    },


    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

}));

const Alternatives = () => {
    const { classes } = useStyles();

    return (
        <Container className={classes.wrapper} size={"100%"}>
            <Container className={classes.wrapper} size={1100}>
                <div className={classes.hero}>
                    <Title className={classes.title} pb={30}>
                        Alternative Downloads
                    </Title>
                    <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'sm', cols: 1 }, { maxWidth: 'md', cols: 2 }]}>
                        {["windows", "linux", "macos", "rpi"].map((os, index) => {
                            return <Card key={index} os={robot[os].os} downloads={robot[os].downloads} icon={robot[os].icon} version={robot[os].version} date={robot[os].release} />
                        })}
                    </SimpleGrid>
                </div>
            </Container>
        </Container>
    );
}

export default Alternatives;

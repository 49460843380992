import React, {useState} from 'react';
import { Helmet } from 'react-helmet';
import Hero from './Hero';
import Users from '../shared/Users';
import Discord from '../shared/Discord';
import blogs from './data'
import Posts from './Posts';

import {
    Center,
    Pagination
} from '@mantine/core';

const Blogs = () => {

    blogs.sort((a, b) => Date.parse(b.Date) - Date.parse(a.Date));

    const itemOnPage = 10;
    const total = blogs.length;
    const maxPagination = blogs.length / itemOnPage +1;

    const [activePage, setPage] = useState(1);

    const beginIndex = (activePage * itemOnPage) - itemOnPage;
    const endIndex = (activePage * itemOnPage) >= total ? total : (activePage * itemOnPage);
    const dataToList = blogs.slice(beginIndex,  endIndex)

    const title = "Blog - Robomotion RPA";

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta name="twitter:title" content={title} />
            </Helmet>   
            <Hero />
            <Posts data={dataToList} />
            <Center>
                <Pagination value={activePage} onChange={setPage} total={maxPagination} pb={50} pt={40} color={"orange"}/>
            </Center>
            <Users />
            <Discord />
        </>
    );
}

export default Blogs;
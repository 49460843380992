import {
    createStyles,
    Title,
    Text,
    Container,
    Center,
    AspectRatio,
    Card,
} from '@mantine/core';

import colors from '../../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin:"auto",
        paddingTop: 0,
        backgroundColor:colors.PrimaryBackground,

    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 80,
    },

    title: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "3.5em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    subTitle: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "2em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    description: {
        color: colors.Primary,
        textAlign: 'center',
        fontSize: 24,
        maxWidth: "900px",
        fontFamily: 'Barlow',
        
    },

    highlight_1: {
        display: "inline-block",
        background: colors.Camboge,
        color: colors.Info
    },

    underlined: {
        textDecoration: "underline wavy #2196F3"
    },

    subBody: {
        marginTop: "40px",
        textAlign: 'center',
        fontSize: 20,
    },

    mainCard: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: "400",
        backgroundColor: colors.Transparent,
    },

    appCard: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: "400",
        backgroundColor: colors.Transparent,
    },

    appName: {
        fontFamily: 'Barlow',
        fontSize: 22,
    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenSM: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },
    highlight: {
        textDecoration: 'underlined'
    }

}));

const UserBase = () => {

    const { classes } = useStyles();

    return (
        <Container size={1100}>
            <Card shadow={"lg"}>
                <AspectRatio ratio={1920 / 960} mx="auto">
                        <video src="https://apps.robomotion.io/videos/designer.mp4" autoPlay muted loop >
                        </video>
                </AspectRatio>
            </Card>
        </Container>
    );
}

export default UserBase;

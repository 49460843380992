import {
    createStyles,
    Container,
    Card,
    Center,
    Title,
    List,
    Text,
    Alert,
    Image,
    Notification,
    Group,
    SimpleGrid,
    ThemeIcon,
} from '@mantine/core';

import colors from '../../../const/colors';
import { useRef } from 'react';

import {
    IconCheck,
} from '@tabler/icons';





const useStyles = createStyles((theme) => ({
    wrapper: {
        margin: "auto",
        paddingBottom: 50,
    },

    hero: {
        position: 'relative',
        zIndex: 1,
    },

    title: {
        textAlign: 'left',
        fontWeight: 700,
        fontSize: "2.2em",
        color: colors.RichBlack,
        fontFamily: 'Barlow',
    },

    topTitle: {
        fontSize: "24px",
        fontWeight: "800",
        color: colors.PrimaryText,
    },

    bottomTitle: {
        fontWeight: 600,
        fontSize: "24px",
        color: colors.Primary,
    },

    description: {
        color: colors.PrimaryText,
        textAlign: 'left',
        fontSize: "22px",
    },

    listItem: {
        color: colors.PrimaryText,
        textAlign: 'left',
        fontSize: 20,
    },

    highlight: {
        fontWeight: 800,

    },
    underlined: {
        textDecoration: "underline wavy #2196F3"
    },

    subDescription: {
        marginTop: "40px",
        textAlign: 'center',
        fontSize: 16,
        color: colors.PrimaryText,
    },


    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

    infoCard: {
        backgroundColor: colors.Transparent,
        maxWidth: 900,
    },

    imageCard: {
        backgroundColor: colors.Transparent,
    },

    imageCardChild: {
        backgroundColor: colors.Transparent,
        borderColor: colors.Accent2,
    },

    holder: {
        backgroundColor:colors.Transparent,
        maxWidth: 1000,
    },

    mainCard: {
        backgroundColor: colors.TertiaryBackground,
    },

    upperCard: {
        backgroundColor: colors.Transparent,
    },

    innerCard: {
        backgroundColor: colors.Transparent,
    },

    imageCard: {
        backgroundColor: colors.SecondaryBackground,
    },

}));

function GetList(data, classes) {

    const lists = data.map((item) => (
        <Card p={5} className={classes.innerCard}>
            <Group justify="space-between" >
                <Text className={classes.bottomTitle} >{item.start}</Text>
            </Group>
            <Text size="lg" >
                {item.rest}
            </Text>
        </Card>
    ));

    return (
        <>
            {lists}
        </>
    );

}

const FeatureView = (feature) => {

    const { classes } = useStyles();

    return (
        <Card className={classes.holder}>
            <Card className={classes.mainCard} pt={10} mt={30} pb={30} radius={"sm"} shadow="sm" withBorder>
                <Card className={classes.upperCard}>
                        <Card className={classes.innerCard} p={0}>

                            <Title className={classes.title} pl={5}>
                                <b>{feature.title.highlight}</b> {' '} {feature.title.normal}
                            </Title>
                            <Text className={classes.description} mb={10} pl={5}>
                                {feature.description}
                            </Text>
                        </Card>
                </Card>
                <SimpleGrid cols={2} spacing={10} breakpoints={[{ maxWidth: 'sm', cols: 1 }, { maxWidth: 'md', cols: 2 }, { maxWidth: 'lg', cols: 2 }]}>
                    { 
                    feature.placement == "left" ?
                    <>
                        <Center>
                            <Card className={classes.infoCard} >
                                {GetList(feature.list, classes)}
                            </Card>
                        </Center>
                        <Center>
                                <Image p={0} m={0} width={400} src={feature.image} alt="Feature image"  className={classes.image} radius={10} />
                        </Center>
                    </>
                    :
                    <>
                        <Center>
                                <Image p={0} m={0} width={400}  src={feature.image} alt="Feature image"  className={classes.image} radius={10} />
                        </Center>
                        <Center>
                            <Card className={classes.infoCard} >
                            {GetList(feature.list, classes)}
                            </Card>
                        </Center>
                    </>
                    }
                </SimpleGrid>
            </Card>
        </Card>

    );
}

export default FeatureView;

const team = [
    {
        "avatar":"/images/about/faik-uygur.jpeg",
        "name":"Faik Uygur",
        "role": "CO-FOUNDER & PRODUCT LEAD",
        "linkedin": ""
    },
    {
        "avatar":"/images/about/osman-mollahamut.jpeg",
        "name":"Osman Mollahamut",
        "role": "CO-FOUNDER & RPA LEAD",
        "linkedin": ""
    },
    {
        "avatar":"/images/about/efe.jpeg",
        "name":"Efe Adalıoğlu",
        "role": "CHIEF SALES OFFICER",
        "linkedin": ""
    },
    {
        "avatar":"/images/about/ilknur-mert.jpeg",
        "name":"İlknur Mert",
        "role": "RPA DEVELOPER",
        "linkedin": ""
    },
    {
        "avatar":"/images/about/aleyna-durmus.jpeg",
        "name":"Aleyna Durmuş",
        "role": "RPA DEVELOPER",
        "linkedin": ""
    },
    {
        "avatar":"/images/about/sueda-gul.jpeg",
        "name":"Süeda Gül",
        "role": "RPA DEVELOPER",
        "linkedin": ""
    },
    {
        "avatar":"/images/about/mert-cosar.jpeg",
        "name":"Mert Coşar",
        "role": "ACCOUNT SPECIALIST",
        "linkedin": ""
    },
    {
        "avatar":"/images/about/cansel-kahraman.jpeg",
        "name":"Cansel Kahraman",
        "role": "RPA DEVELOPER",
        "linkedin": ""
    },
    {
        "avatar":"/images/about/melisa-durmus.jpeg",
        "name":"Melisa Durmuş",
        "role": "JUNIOR RPA DEVELOPER",
        "linkedin": ""
    },
    {
        "avatar":"/images/about/omer-akin.jpeg",
        "name":"Ömer Akın Aydın",
        "role": "SALES MANAGER",
        "linkedin": ""
    },
    {
        "avatar":"/images/about/aysegul-ceber.jpeg",
        "name":"Ayşegül Çeber",
        "role": "SALES DEVELOPMENT REP.",
        "linkedin": ""
    },
    {
        "avatar":"/images/about/selim-gizem-ozkan.jpeg",
        "name":"Selin Gizem Özkan",
        "role": "RPA DEVELOPER",
        "linkedin": ""
    },
    {
        "avatar":"/images/about/burcu-ihtiyar.jpeg",
        "name":"Burcu İhtiyar",
        "role": "PROJECT MANAGER",
        "linkedin": ""
    },
    {
        "avatar":"/images/about/ipek-altinoluk.jpeg",
        "name":"İpek Altınoluk",
        "role": "HR Generalist",
        "linkedin": ""
    },
    {
        "avatar":"/images/about/rohat-ucar.jpeg",
        "name":"Rohat Uçar",
        "role": "SOFTWARE DEVELOPER",
        "linkedin": ""
    },
    {
        "avatar":"/images/about/felya-bilgen.jpeg",
        "name":"Felya Bilgen",
        "role": "MARKETING SPECIALIST",
        "linkedin": ""
    },
    {
        "avatar":"/images/about/halilcan-ulker.jpeg",
        "name":"Halilcan Ülker",
        "role": "CURRICULUM DEVELOPER",
        "linkedin": ""
    },
    {
        "avatar":"/images/about/anil-yarimca.jpeg",
        "name":"Anıl Yarımca",
        "role": "PARTNERSHIP MANAGER",
        "linkedin": ""
    },
    {
        "avatar":"/images/about/burcu-evren.jpeg",
        "name":"Burcu Evren",
        "role": "RPA DEVELOPER",
        "linkedin": ""
    },
    {
        "avatar":"/images/about/eren-aksoy.jpeg",
        "name":"Eren Aksoy",
        "role": "JUNIOR SOFTWARE DEVELOPER",
        "linkedin": ""
    },
    {
        "avatar":"/images/about/ikram-mert.jpeg",
        "name":"İkram Mert",
        "role": "JUNIOR SOFTWARE DEVELOPER",
        "linkedin": ""
    },
    {
        "avatar":"/images/about/mustafa-dirier.jpeg",
        "name":"Mustafa Dirier",
        "role": "FLUTTER DEVELOPER",
        "linkedin": ""
    },
    {
        "avatar":"/images/about/hasan-gursoy.jpeg",
        "name":"Hasan Gürsoy",
        "role": "SOFTWARE DEVELOPER",
        "linkedin": ""
    },
    {
        "avatar":"/images/about/latif-uluman.jpeg",
        "name":"Latif Uluman",
        "role": "SOFTWARE DEVELOPER",
        "linkedin": ""
    },
];

export default team;

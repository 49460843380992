import { Helmet } from 'react-helmet';
import Hero from './Hero';
import Discord from '../shared/Discord';
import Alternatives from './Alternatives';

const Downloads = () => {
    const title = "Downloads - Robomotion RPA";
    return (
        <>
        
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta name="twitter:title" content={title} />
            </Helmet>

            <Hero />
            <Alternatives />
            <Discord />
        </>
    );
}

export default Downloads;
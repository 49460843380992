
import experts from './data';
import ExpertCard from './ExpertCard';

import {
    createStyles,
    Title,
    Text,
    Container,
    SimpleGrid,
    Center
} from '@mantine/core';
import colors from '../../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin:"auto",
        backgroundColor: colors.PrimaryBackground,
    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 50,
    },

    title: {
        textAlign: 'center',
        fontWeight: 500,
        fontSize: "4.2em",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',

        [theme.fn.smallerThan('lg')]: {
            fontSize: "3em",
        },
    },
    description: {
        color: colors.Primary,
        textAlign: 'center',
        fontSize: 22,
        maxWidth: "740px",
    },

    highlight: {
        fontWeight: 800,

    },
    underlined: {
        textDecoration: "underline wavy #2196F3"
    },

    subDescription: {
        marginTop: "40px",
        textAlign: 'center',
        fontSize: 16,
    },

    mainCard: {

    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

}));

const SolutionExperts = () => {
    const { classes } = useStyles();

    return (
        <Container className={classes.wrapper} size={"%100"} pb={100} pt={50} m={0} >
            <div className={classes.hero}>
                <Center>
                    <Title className={classes.title}>
                        Robomotion <b>Solution Experts</b>
                    </Title>
                </Center>
                <Container px={30} size={1100}>
                    <Center>
                            <Text size="xl" className={classes.description} pb={40}>
                                Find a Robomotion expert with the right skills for your business and get help to create your automations.
                            </Text>
                    </Center>
                </Container>

                <Container px={30} size={1100}>
                    <Center>
                    <SimpleGrid cols={3} spacing={40} breakpoints={[{ maxWidth: 'sm', cols: 1 }, { maxWidth: 'md', cols: 2 }]}>
                        {experts.map((e, index) => <ExpertCard key={index} name={e.name} avatar={e.avatar} linkedin={e.linkedin} offer={e.offer} languages={e.languages} /> )}
                    </SimpleGrid>
                    </Center>
                </Container>
            </div>
        </Container>
    );
}

export default SolutionExperts;

import { Helmet } from 'react-helmet';
import Hero from './Hero';
import Users from '../../shared/Users';
import Discord from '../../shared/Discord';
import HowItworks from './HowItWorks';
import SolutionExperts from './SolutionExperts';

const Experts = () => {
    const title = "Solution Experts - Robomotion RPA"
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta name="twitter:title" content={title} />
            </Helmet>    
            <Hero />
            <HowItworks />
            <SolutionExperts />
            <Users />
            <Discord />
        </>
    );
}

export default Experts;
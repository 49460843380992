import { 
	createStyles,
	Card, 
	Image, 
	Group, 
	Text,  
	Anchor
} from '@mantine/core';

import colors from '../../const/colors';


const useStyles = createStyles((theme) => ({
	card: {
		backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
	},

	title: {
        color: colors.Primary,
		fontSize: 28,
	},

	footer: {
		padding: `${theme.spacing.xs}px ${theme.spacing.lg}px`,
		marginTop: theme.spacing.md,
		borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
			}`,
	},

	description: {
        color: colors.Primary,
        textAlign: 'left',
        fontSize: 20,
        fontFamily: 'Barlow',
        
    },


}));

const BlogCard = ({ url, cover, title, description, date }) => {

	const { classes } = useStyles();

	return (
		
		<Card withBorder p="lg" radius="md" className={classes.card} m="lg">
			<Card.Section mb="sm">
				<Anchor href={url} target="_blank">
					<Image src={"/images/blog/" + cover} alt={title} height={180} />
				</Anchor>
			</Card.Section>

			<Text weight={700} className={classes.title} mt="xs">
				<Anchor href={url} target="_blank" className={classes.title}>
				{title}
				</Anchor>
			</Text>

			<Group mt="xs">
				<div>
					<Text size="md" className={classes.description}>
						{description}...
					</Text>
				</div>
			</Group>

			<Group mt="lg">
				<div>
					<Anchor href={url} target="_blank">
						<Text
						variant="gradient"
						gradient={{ from: 'indigo', to: 'cyan', deg: 45 }}
						sx={{ fontFamily: 'Greycliff CF, sans-serif', textDecoration: "none !important", }}
						ta="center"
						fz="md"
						fw={700}
						>
						READ MORE
						</Text>
					</Anchor>
				</div>
			</Group>

			<Card.Section className={classes.footer} height="100">
				<Group position="apart" height="100">
					<Text size="xs" color="dimmed" height="100">
						{date}
					</Text>
				</Group>
			</Card.Section>
		</Card>
		
	);
}

export default BlogCard;

import {
    createStyles,
    Title,
    Container,
    SimpleGrid,
    Center,
} from '@mantine/core';

import team from '../../const/team';
import TeamCard from './TeamCard';
import colors from '../../const/colors.jsx';

const useStyles = createStyles((theme) => ({
    wrapper: {
        position: 'relative',
        margin: "auto",
        display: 'flex',
        paddingTop: 80,

        '@media (max-width: 755px)': {
            paddingTop: 80,
        },
    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 80,
    },

    title: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "3.5em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    subTitle: {
        textAlign: 'center',
        fontWeight: 300,
        fontSize: "2em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    description: {
        color: colors.Primary,
        textAlign: 'center',
        fontSize: 24,
        maxWidth: "800px",
        fontFamily: 'Barlow',
        
    },


    subDescription: {
        marginTop: "80px",
        textAlign: 'center',
        fontSize: 16,
    },


    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

    img: {
        maxWidth: 650,
    },

}));

const TeamList = () => {

    const { classes } = useStyles();

    return (
        <Center>
            <Container className={classes.wrapper} size={"%100"} pb={100} m={0} mb={-3}>
                <div className={classes.hero}>
                    <Title className={classes.title}>
                        Meet Our Team
                    </Title>

                    <Container px={30} size={1100}>
                        <Center>
                            <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'sm', cols: 1 }, { maxWidth: 'md', cols: 2 }]}>
                                {team.map((e, index) => <TeamCard key={index} name={e.name} avatar={e.avatar} role={e.role} linkedin={e.linkedin} />)}

                            </SimpleGrid>
                        </Center>
                    </Container>
                </div>
            </Container>
        </Center>
    );
}

export default TeamList;

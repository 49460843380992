import { Container, Title, Accordion, createStyles } from '@mantine/core';
import colors from '../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin: "auto",
        paddingTop: 40,
        paddingBottom: 40,

    },

    hero: {
        position: 'relative',
        zIndex: 1,
    },

    title: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: "2em",
        lineHeight: "1.2",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,

    },

    subTitle: {
        color: colors.PrimaryText,
        textAlign: 'center',
        letterSpacing: "-0.02em",
        lineHeight: "1.4",
        fontSize: 48,
        fontWeight: 800,
    },

    description: {
        color: colors.PrimaryText,
        textAlign: 'center',
        letterSpacing: "-0.02em",
        lineHeight: "1.4",
        fontSize: 24,
        marginTop: 60,
    },

    subDescription: {
        marginTop: "20px",
        textAlign: 'center',
        fontSize: 16,
    },


    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

}));

const faqs = [

    {
        "title": "What is Self-Hosting?",
        "description": "This license allows you to self-host the entire Robomotion platform, not just the robot, on your own server using a custom domain. Alternatively, you can choose to use our hosted backend server in the cloud. Your license enables the use of a backend either on your own server or on robomotion.io. Additionally, you can transfer the robot licenses you own into your self-hosted environment.",
        "value": "8"
    },
    {
        "title": "How can I deploy On-Premise?",
        "description": "The Robomotion backend consists of multiple services and databases deployed on a Kubernetes cluster. You will need a managed Kubernetes cluster or your own Kubernetes installation on a Linux machine. Custom support is available for a one-time setup fee and annual upgrade and maintenance fees by contacting sales@robomotion.io.",
        "value": "7"
    },
    {
        "title": "Can it run on a closed network?",
        "description": "Yes, after the initial setup, you only need to activate the license for your robots on a self-hosted system. Following this, no internet connection is necessary, and Robomotion can operate within your closed network.",
        "value": "9"
    },
    {
        "title": "Can I transfer my data?",
        "description": "It is not possible to transfer data. We do not offer migration between cloud and self-hosted systems. It requires an installation from scratch. However, you can export your flows and manually import them later.",
        "value": "4"
    },
    {
        "title": "What is Public Cloud, Private Cloud, and On-Premise?",
        "description": "The Public Cloud refers to the cloud hosted by robomotion.io. Private Cloud encompasses any cloud hosting provided by GCP, AWS, and Azure that supports managed Kubernetes services. On-Premise involves using either VPS or virtual machine servers within your company's network.",
        "value": "6"
    },    
    {
        "title": "What are the limits for?",
        "description": "Limits apply if you choose to use our hosted environment. For on-premise setups, the limits are only based on the robot licenses you possess. Because you utilize your own CPU, memory, and storage resources on self-hosted setup, we do not impose limits on triggers, queues, or schedules for on-premise setups. We simply activate the licenses you have, and you can operate as many robots as your licenses permit.",
        "value": "5"
    },
    {
        title: 'What is a Flow?',
        description: "A Flow refers to an automation project created by developers using the Robomotion Flow Designer. It consists of a sequence of steps, which can be unlimited, designed to automate tasks. Flows are executed by robot software that can be installed on a developer's machine, a server, or run in our cloud environment on-demand. This flexibility allows for a wide range of automation possibilities, from simple tasks to complex processes, tailored to various operational needs.",
        value: '-1'
    },
    {
        title: 'What is a Robot?',
        description: "In the context of Robotic Process Automation (RPA), a robot refers to a digital worker or software agent designed to automate tasks by mimicking human interactions with digital systems and software. This technology enables the creation of automated solutions that can perform repetitive tasks without human intervention. You have the flexibility to deploy a robot on your own machine to run automations on-premises, ensuring complete control and security of your data. Alternatively, you can opt for our cloud run option, which allows you to execute automations in our cloud environment without the need for any installations on your part. This provides a seamless and scalable way to leverage automation technology, regardless of your infrastructure.",
        value: '0'
    },
    {
        title: 'What is an Application Robot?',
        description: "An Application Robot functions as a designated slot for running applications. With Robomotion, users have the flexibility to utilize applications from our App Store or develop and deploy their own custom applications. These can be managed and executed directly from the Robomotion Apps dashboard. The concept of an Application Robot essentially governs the quantity of applications you can run concurrently. An application robot can only run a single flow, which is the designated flow of the Application Instance.",
        value: '1'
    },
    {
        title: 'What is a Development Robot?',
        description: 'A Development Robot is a specialized robot in Robomotion, created specifically for developers to design flows for automation projects. Developers utilize it within the Robomotion Flow Designer to construct these flows. It has the capability to execute any flow configured in the Designer. However, it cannot schedule flows or monitor for triggers. Its main function is to assist in the testing and refinement of flows during the development phase, ensuring they are ready for deployment in real-world scenarios.',
        value: '2'
    },
    {
        title: 'What is a Production Robot?',
        description: 'A Production Robot is utilized when a flow is prepared for deployment in a live environment. These robots are capable of running any flow, allowing for the scheduling of flows as needed. Additionally, Production Robots are equipped to monitor multiple trigger events, such as changes in the file system, email notifications, HTTP requests, queue insertions, and hardware triggers. They can initiate flows based on these trigger events, making them essential for automating tasks in real-time operational settings.',
        value: '3'
    },

];



const FAQ = () => {
    const { classes } = useStyles();

    const ListQuestions = (x) => {
        return x.map((faq, index) => (
            <Accordion.Item key={index} className={classes.item} value={faq.value}>
                <Accordion.Control>{faq.title}</Accordion.Control>
                <Accordion.Panel>{faq.description}</Accordion.Panel>
            </Accordion.Item>
        ));
    }

    return (
        <Container size="100%" className={classes.wrapper}>
            <Container size="sm" className={classes.wrapper}>

                <Title align="center" className={classes.title} pb={50}>
                    Frequently Asked Questions
                </Title>

                <Accordion variant="separated">
                    {ListQuestions(faqs)}
                </Accordion>
            </Container>
        </Container>
    );
}

export default FAQ;
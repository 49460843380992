const experts = [
    {
        "name": "Alex Maxi",
        "avatar": "/images/partners/experts/alex-maxi.jpeg", 
        "linkedin": "https://www.linkedin.com/in/alex-maxi-53b373203/",
        "offer": "Robomotion Training (Personal / Groups), Consulting & Technical Support on Robomotion Projects, Advanced Public Data Gathering and Processing Automation, Advanced Graphics, TTS and Video Automation",
        "languages": "English, Spanish"
    },
    {
        "name": "Kris R",
        "avatar": "/images/partners/experts/kris-r.jpeg", 
        "linkedin": "https://www.linkedin.com/in/krisautomates/",
        "offer": "Automation Consulting, Technology Consulting, Lean Process Optimization, Intelligent Document Processing, OCR, Open CV, Productivity Management Solutions, Analytics, Intelligent Bots, Discrete system integration solutions and Bots as a Service.",
        "languages": "English, Hindi, Marathi, Tamil"
    },
    {
        "name": "Muhammad Arif",
        "avatar": "/images/partners/experts/arif.jpeg", 
        "linkedin": "https://www.linkedin.com/in/aareeph/",
        "offer": "I will help you automate your business tasks using Robomotion robots. Parsing PDF files, extracting data from emails, and working with Google Workspace Apps (Gmail, Google Sheets, Google Docs) are my areas of expertise.",
        "languages": "English, Urdu"
    }

];

export default experts;

import {
    createStyles,
    Title,
    Text,
    Card,
    Container,
    Center,
    SimpleGrid,
} from '@mantine/core';

import colors from '../../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin:"auto",
        backgroundColor: colors.PrimaryBackground,
    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 50,
    },

    title: {
        textAlign: 'center',
        fontWeight: 500,
        fontSize: "34px",
        color: colors.PrimaryText,
        marginBottom: theme.spacing.sm,
        fontFamily: 'Barlow',
        maxWidth: "750px",

        [theme.fn.smallerThan('lg')]: {
            fontSize: 26,
        },
    },

    description: {
        color: colors.Primary,
        textAlign: 'center',
        fontSize: 22,
        maxWidth: "740px",
    },

    highlight: {
        fontWeight: 800,

    },
    underlined: {
        textDecoration: "underline wavy #2196F3"
    },

    subDescription: {
        marginTop: "40px",
        textAlign: 'center',
        fontSize: 16,
    },

    mainCard: {

    },


    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

}));

const HowItworks = () => {

    const { classes } = useStyles();

    return (
        <Container className={classes.wrapper} size={1200}>
            <div className={classes.hero}>
                <Center>

                <Card shadow={"sm"} className={classes.mainCard} p={50} >
                        <Center>
                            <Title className={classes.title}> 
                                How Does It Work?
                            </Title>
                        </Center>

                        <Container p={16} size={1100}>
                            <Center>
                                <Text className={classes.description}>
                                <b>1. Join Community:</b> Join our Discord Community at community.robomotion.io and become an active member. We have a growing community that values help and sharing.
                                </Text>
                            </Center>
                            <Center pt={32}>
                                <Text className={classes.description}>
                                <b>2. Reach:</b> Reach out to people in our #job-posts channel on Discord for help, or you can also reach more from your Upwork and Fiverr accounts if you add Robomotion expertise to your profile.
                                </Text>
                            </Center>
                            <Center pt={32}>
                                <Text className={classes.description}>
                                <b>3. Apply:</b> Once you have reached to 5 successfully completed jobs. Complete our form below and we will contact you for details.
                                </Text>
                            </Center>

                        </Container>
                    </Card>
                    </Center>
            </div>
        </Container>

    );
}

export default HowItworks;

import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from "react-router-dom";
import frontmatter from 'frontmatter';
import Hero from './Hero';
import Users from '../shared/Users';
import Discord from '../shared/Discord';
import Body from './Body';
import CTA from './CTA';

const Blog = () => {
    const { slug } = useParams();
    const [post, setPost] = useState('');

    useEffect(() => {
        import(`./blogs/${slug}/index.md`).then(res => {
            fetch(res.default)
            .then(res => res.text())
            .then(res => setPost(res));
        })
        .catch(err => console.log(err));
    });

    const mrkdwn = frontmatter(post);
    const data = mrkdwn.data;

    const title = data && data.title ? data.title : "Robomotion RPA";
    const image = data && data.coverImage ? window.location.origin + "/images/blog/" + data.coverImage + "?auto=compress,format&rect=0,0,1200,720&w=1200&h=720" : "";
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta name="twitter:title" content={title} />
                <meta property="og:image" content={image} />
                <meta name="twitter:image" content={image} />
            </Helmet>   
            <Hero data={data} />
            <Body content={mrkdwn.content} />
            <Users />
            <Discord />
        </>
    );
}

export default Blog;
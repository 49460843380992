import { Helmet } from 'react-helmet';
import Hero from './Hero';
import Users from '../shared/Users';
import Discord from '../shared/Discord';
import Records from './Records';

const Webinars = () => {


    const title = "Webinars - Robomotion RPA"

    return (
        
        <>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta name="twitter:title" content={title} />
            </Helmet>        
            <Hero />
            <Records />
            <Users />
            <Discord />
        </>
    );
}

export default Webinars;
import { createStyles, Text, Container, ActionIcon, Group, Anchor } from '@mantine/core';
import { IconBrandTwitter, IconBrandYoutube, IconBrandInstagram, IconBrandFacebook, IconBrandLinkedin, IconBrandGithub } from '@tabler/icons';
import Logo from './Logo';
import colors from '../../const/colors.jsx'

const useStyles = createStyles((theme) => ({
  footer: {
    paddingTop: theme.spacing.xl * 2,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
      }`,
  },

  logo: {
    maxWidth: 250,
    minWidth: 250,

    [theme.fn.smallerThan('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  description: {
    marginTop: 5,

    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.xs,
      textAlign: 'center',
    },
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  groups: {
    display: 'flex',
    flexWrap: 'wrap',

    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  wrapper: {
    width: 180,
  },

  link: {
    display: 'block',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
    fontSize: theme.fontSizes.sm,
    paddingTop: 3,
    paddingBottom: 3,

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  title: {
    fontSize: theme.fontSizes.lg,
    fontWeight: 700,
    marginBottom: theme.spacing.xs / 2,
    color: colors.PrimaryText,
  },

  afterFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing.xl,
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
      }`,

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
    },
  },

  social: {
    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.xs,
    },
  },
}));

const links = [
  {
    label: "Terms of Use",
    link: '/legal/terms'
  },
  {
    label: "Privacy Policy",
    link: '/legal/privacy'
  },
  {
    label: "Cookie Policy",
    link: '/legal/privacy#cookie-policy'
  }
];

const data = [
  {
    "title": "Company",
    "links": [
      { "label": "About", "link": "/about" },
      { "label": "Careers", "link": "https://www.linkedin.com/company/robomotion/jobs" },
      { "label": "Blog", "link": "/blog" }
    ]
  },
  {
    "title": "Help",
    "links": [
      { "label": "Discord Community", "link": "https://community.robomotion.io/" },
      { "label": "Forum", "link": "https://forum.robomotion.io/" },
      { "label": "Contact Us", "link": "mailto:support@robomotion.io" },
      { "label": "Releases", "link": "https://feedback.robomotion.io/announcements" },
      { "label": "ROI Calculator", "link": "/roi-calculator" },
    ]
  },
  {
    "title": "Industries",
    "links": [
      { "label": "Accounting and Finance", "link": "/solutions/accounting-and-finance-robotic-process-automation" },
      { "label": "Customer Services", "link": "/solutions/customer-services" },
      { "label": "Insurance", "link": "/solutions/insurance" },
      { "label": "Logistics", "link": "/solutions/logistics" },
      { "label": "Marketing", "link": "/solutions/marketing" },
      { "label": "Retail", "link": "/solutions/retail" }
    ]
  },
  {
    "title": "Use Cases",
    "links": [
      { "label": "CRM Automation", "link": "/solutions/use-case/crm-automation" },
      { "label": "Desktop Automation", "link": "/solutions/use-case/desktop-automation" },
      { "label": "Document Automation", "link": "/solutions/use-case/document-automation" },
      { "label": "Excel Automation", "link": "/solutions/use-case/excel-automation" },
      { "label": "SAP Automation", "link": "/solutions/use-case/sap-automation" },
      { "label": "Web Automation", "link": "/solutions/use-case/web-automation" }
    ]
  },
  {
    "title": "Download",
    "links": [
      { "label": "Linux", "link": "https://packages.robomotion.io/releases/ubuntu/robomotion_24.7.0_amd64.deb" },
      { "label": "MacOS", "link": "https://packages.robomotion.io/releases/macos/Robomotion-24.7.0.dmg" },
      { "label": "Windows", "link": "https://packages.robomotion.io/releases/windows/RobomotionSetup-x64-24.7.0.exe" }
    ]
  },
]


const Footer = () => {

  const { classes } = useStyles();

  const items = links.map((link) => (
    
    <Anchor
      color="dimmed"
      key={link.label}
      href={link.link}
      sx={{ lineHeight: 1 }}
      size="sm"
    >
      {link.label}
    </Anchor>
  ));

  const groups = data.map((group) => {
    const links = group.links.map((link, index) => (
      <Text
        key={index}
        className={classes.link}
        component="a"
        href={link.link}
      //onClick={(event) => event.preventDefault()}
      >
        {link.label}
      </Text>
    ));

    return (
      <div className={classes.wrapper} key={group.title}>
        <Text className={classes.title}>{group.title}</Text>
        {links}
      </div>
    );
  });

  return (
    <footer className={classes.footer}>
      <Container className={classes.inner} size="xl">
        <div className={classes.logo}>
          <Logo />
          <Text size="xs" color="dimmed" className={classes.description}>
            Automate Everything!
          </Text>
        </div>
        <div className={classes.groups}>{groups}</div>
      </Container>
      <Container className={classes.afterFooter} size="xl">
        <Text color="dimmed" size="sm">
          © 2020-2024 Robomotion. All rights reserved.
        </Text>

        <Group className={classes.links}>{items}</Group>

        <Group spacing={0} className={classes.social} position="right" noWrap>

        <ActionIcon size="lg" variant="default" radius="xl" mx={3} component="a" href="https://twitter.com/robomotionio">
            <IconBrandTwitter size={18} stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg" variant="default" radius="xl" mx={3} component="a" href="https://www.youtube.com/c/Robomotion">
            <IconBrandYoutube size={18} stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg" variant="default" radius="xl" mx={3} component="a" href="https://www.instagram.com/robomotion.io/" >
            <IconBrandInstagram size={18} stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg" variant="default" radius="xl" mx={3} component="a" href="https://www.linkedin.com/company/robomotion/">
            <IconBrandLinkedin size={18} stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg" variant="default" radius="xl" mx={3} component="a" href="https://github.com/robomotionio">
            <IconBrandGithub size={18} stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg" variant="default" radius="xl" mx={3} component="a" href="https://www.facebook.com/robomotionrpa/">
            <IconBrandFacebook size={18} stroke={1.5} />
          </ActionIcon>
        </Group>
      </Container>
    </footer>
  );
}

export default Footer;




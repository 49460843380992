const partners = [
    {
        "name": "PwC",
        "logo": "/images/partners/enterprise/partner-pwc.png", 
        "url": "https://pwc.com.tr/",
        "location" : "Istanbul, Turkey",
        "description" : "PwC Türkiye delivers innovative technology solutions and digital platforms designed to address evolving market challenges. With a focus on rapid deployment and accelerated value delivery, PwC Türkiye leverages decades of experience to provide definitive solutions for organizational needs. Robomotion collaborates with PwC Türkiye to implement advanced automation projects using RPA technology, enhancing operational efficiency and driving greater value."
    },
    {
        "name": "ABBYY",
        "logo": "/images/partners/enterprise/partner-abbyy.png", 
        "url": "https://www.abbyy.com/",
        "location" : "Milpitas, California",
        "description" : "ABBYY is a leader in intelligent automation solutions, using AI to transform data into actionable intelligence. Their intelligent document processing technology enables enterprises to turn data from any document into valuable insights, driving smarter decisions and better outcomes. As an official authorized partner of ABBYY, Robomotion offers ABBYY's powerful Vantage OCR Skill and FlexiCapture SDK for advanced document understanding and management. This partnership allows us to provide cutting-edge solutions that enhance efficiency and clarity in business processes."
    },
    {
        "name": "PlakTech",
        "logo": "/images/partners/enterprise/partner-plak.png", 
        "url": "https://www.plaktech.com/",
        "location" : "Istanbul, Turkey",
        "description" : "Plak Tech, a solution partner of Robomotion, is dedicated to bringing innovation and efficiency to business processes through Robotic Process Automation (RPA). In partnership with Robomotion, Plak Tech implements advanced automation projects, leveraging our RPA technology to transform business operations and optimize resource use. Plak Tech's expert team provides customized solutions that enhance competitiveness and strategic resource allocation for businesses."
    },
    {
        "name": "Oakland",
        "logo": "/images/partners/enterprise/partner-oakland.png", 
        "url": "https://odooerp.ae",
        "location": "Sharjah, UAE",
        "description": "Description: Oakland OdooERP.ae provides top-tier ERP solutions designed to enhance business efficiency and intelligence. Through our partnership, Robomotion complements Oakland's ERP solutions with advanced RPA technology, automating custom projects to boost productivity and simplify operations. Since 2016, Oakland has established a strong reputation in the UAE and beyond for delivering exceptional ERP solutions."
    },
    {
        "name": "Renova Consulting",
        "logo": "/images/partners/enterprise/partner-renova.jpg", 
        "url": "https://renovaconsulting.com",
        "location":"Istanbul, Turkey",
        "description":"Renova Consulting specializes in guiding businesses through their digital transformation journey with innovative RPA (Robotic Process Automation) solutions. Their expert consultants focus on optimizing business processes to achieve fast, error-free, and automated workflows. Robomotion collaborates with Renova Consulting to implement advanced automation projects using RPA technology, ensuring optimized and efficient business processes."
    }, 
    {
        "name": "VBM",
        "logo": "/images/partners/enterprise/partner-vbm.png", 
        "url": "https://vbm.com.tr",
        "location":"Istanbul, Turkey",
        "description":"VBM Technology & Business Solutions provides innovative consultancy and solutions in RPA, CRM, Business Intelligence, and SQL Server Optimization to enhance customer productivity and develop competitive processes through expert, tailored solutions. Robomotion collaborates with VBM Technology & Business Solutions to implement advanced automation projects using RPA technology, ensuring efficient and effective business operations."
    },
    {
        "name": "Simplify",
        "logo": "/images/partners/enterprise/partner-simplify.png", 
        "url": "https://www.simplify.rs",
        "location":"Belgrade, Serbia",
        "description":"Simplify specializes in robotic process automation and process optimization, driving digital transformation and systems management. Simplify create and implement solutions that accelerate companies into the future, with a successful track record across the globe. Robomotion collaborates with Simplify to implement advanced automation projects using RPA technology, ensuring efficient and transformative business processes."
    },
    {
        "name": "ATP",
        "logo": "/images/partners/enterprise/partner-atp.jpeg", 
        "url": "https://www.atptech.com",
        "location":"Istanbul, Turkey",
        "description":"ATP Tech develops mission-critical enterprise software and system solutions to optimize business processes, empowering clients with flexibility, agility, and a competitive edge. With hundreds of digital transformation projects across Türkiye, China, and EMEA, ATP TECH is a strategic partner in digital transformation and future readiness. Robomotion collaborates with ATP TECH to implement advanced automation projects using RPA technology, enhancing efficiency and driving business success."
    },
    {
        "name": "Teknoser",
        "logo": "/images/partners/enterprise/partner-teknoser.png", 
        "url": "https://teknosergroup.com/",
    },
];

export default partners;
import { Box, LoadingOverlay } from "@mantine/core";
import { useEffect, useState } from "react";

const LayoutWrapper = (props) => {
    
    const initialState = () => ({
        loading: true,
    });

    const [state, setState] = useState(initialState);

    useEffect(() => {
        window.addEventListener('load', () => {
            setTimeout(() => {
                setState({ ...state, loading: false })
            }, 500);
        });
    }, [state]);

    setTimeout(() => {
        setState({ ...state, loading: false })
    }, 1000);

    return (
        <Box>
            <LoadingOverlay visible={state.loading} overlayBlur={200} zIndex={1000001} />
            {props.children}
        </Box>
    );
}

export default LayoutWrapper;
const data = [
    {
        topTitle: "One-Click SEO friendly AI content creator",
        title: {
            highlight: "SEO",
            normal: "AI Writer",
        },
        badge: <a href="https://www.producthunt.com/posts/seo-ai-writer?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-seo&#0045;ai&#0045;writer"><img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=445818&theme=neutral&period=daily" alt="SEO&#0032;AI&#0032;Writer - One&#0045;Click&#0032;SEO&#0032;friendly&#0032;AI&#0032;content&#0032;creator | Product Hunt"  width="250" height="54" /></a>,
        description: "Use the SEO AI Writer for efficient SEO-optimized article creation. Just input your query, and it analyzes the top 20 Google search results, generating content that reflects successful SEO practices, including keyword distribution and content structure, to enhance your search engine ranking.",
        bottomTitle: "Key Features",
        list: [
            { start: "Country and Language Selection:", rest: "The user specifies their preferred country and language for the SEO content." },
            { start: "Enter Target Query:", rest: "The user inputs a target search query relevant to their content goals." },
            { start: "Automated Google Search:", rest: "The system automatically searches for the query on Google." },
            { start: "Scrape Search Results:", rest: "It then scrapes the search results for data analysis." },
            { start: "N-Gram Frequency Analysis:", rest: "The automation visits each top-ranking page to conduct an n-gram frequency analysis, identifying common phrases and terms." },
            { start: "Blog Post Title Generation:", rest: "It suggests various blog post titles based on the analysis. The user selects their preferred title." },
            { start: "Keyword Suggestions:", rest: "The system generates keyword ideas from the n-gram analysis. The user can select suggested keywords or add new ones." },
            { start: "Blog Outline Creation:", rest: "An initial blog outline is generated for the user to review and edit as needed." },
            { start: "Draft Article Generation:", rest: "After reviewing each selection, the automation generates a draft article that aligns with the strategies of top-ranking articles." },
            { start: "WordPress Draft Publishing:", rest: "The user has the option to directly publish the draft article to WordPress as a draft for further editing or immediate publication." }
        ],
        wip: false,
        image: '/images/apps/SEOWriter_Placeholder.png',
        video : 'https://www.youtube.com/embed/nJquPqCGJoU?si=pJyqHlEBytBs6XpN',
        asset : 'video',
        url: 'https://seo-ai-writer-trial.robomotion.app',
        edit: 'https://appstore.robomotion.io/designer/shared/Q9AaFxQbopUspTCBMEgQUw',
        placement: "left",
        logo: "./images/apps/SEOWriter_SM.png",

    },
    {
        topTitle: "Multilingual Dubbing Made Easy",
        title: {
            highlight: "Video",
            normal: "Dubber",
        },
        description: "Robomotion Video Dubber is your go-to app for video dubbing, designed to create multilingual content. It separates audio tracks from videos, transcribes the dialogue, and then translates it into various languages. Perfect for content creators & startups!",
        bottomTitle: "Key Features",
        list: [
            { start: "Upload Video:", rest: "Begin by uploading the video file you wish to dub." },
            { start: "Select Translation Language:", rest: "Choose the target language into which you want to translate the video's audio." },
            { start: "Transcribe Audio:", rest: "The software automatically transcribes the video's audio into text in the original language." },
            { start: "Translate Transcript:", rest: "The original language transcript is translated into the target language." },
            { start: "Edit Transcript:", rest: "Review and edit the translated transcript to ensure accuracy and suitability." },
            { start: "Dub Video:", rest: " The edited transcript is used to generate the dubbed audio, which is then synchronized and merged with the video." }
        ],
        image:  '/images/home/videodubber.gif',
        badge: '',
        video : 'https://www.youtube.com/embed/mJlmb8eD0wg?si=848uoszgnX24F-21',
        asset : 'video',
        url: 'https://video-dubber-demo.robomotion.app/',
        edit: 'https://appstore.robomotion.io/designer/shared/XC6zVa5avKNwJf9a8JW2Do',
        placement: "left",
        logo: "./images/apps/VideoDubber_SM.png",
 
    },
    {
        topTitle: "Automate Your Custom Outreach Videos",
        title: {
            highlight: "Outreach",
            normal: "Video Creator",
        },
        badge: '',
        description: "Supercharge your prospecting with Outreach Video Creator. Merge your video message with any website, creating personalized videos for impactful engagement. Boost conversions and captivate your audience with compelling videos in your email sequences.",
        bottomTitle: "Key Features",
        list: [
            { start: "Upload Marketing Video Message:", rest: "Start by uploading the marketing video you wish to use for outreach." },
            { start: "Upload Prospect List:", rest: "Provide a list containing the names and website links of your target prospects." },
            { start: "Automated Website Visit: ", rest: "The system automatically opens a Chrome browser and navigates to each prospect's website." },
            { start: "Video Embedding:", rest: "It embeds your marketing video into the bottom right corner of the website, applying a rounded effect similar to Loom recordings." },
            { start: "Simulated Browsing:", rest: "The browser simulates human-like scrolling up and down the webpage." },
            { start: "Desktop Recording:", rest: "The entire desktop, including the embedded video and simulated browsing, is recorded to create a new video." },
            { start: "Upload to Loom:", rest: "This newly created video is automatically uploaded to Loom for hosting." },
            { start: "Campaign Readiness:", rest: "The video is now ready for use in personalized outreach campaigns." },
        ],
        image:  '/images/home/outreach.gif',
        video : 'https://www.youtube.com/embed/WGj5gLBtSa0?si=8I6RBZDeZeKmBlzA',
        asset : 'video',
        url: 'https://app.robomotion.io/create',
        edit: 'https://appstore.robomotion.io/designer/shared/RdYMEfTxJP7Cfq8qwufaz6',
        placement: "right",
        logo: "./images/apps/OutreachVideoCreator_SM.png",

    },



    {
        topTitle: "Create AI-Generated Clips from your Videos!",
        title: {
            highlight: "",
            normal: "Clip Generator",
        },
        badge: '',
        description: "Clip Generator is an AI-driven video editing tool developed to transform extensive videos into concise clips effortlessly with a single click, leveraging the capabilities of OpenAI technology.",
        bottomTitle: "Key Features",
        list: [
            { start: "Country and Language Selection:", rest: "The user specifies their preferred country and language for the SEO content." },
            { start: "Enter Target Query:", rest: "The user inputs a target search query relevant to their content goals." },
            { start: "Automated Google Search:", rest: "The system automatically searches for the query on Google." },
            { start: "Scrape Search Results:", rest: "It then scrapes the search results for data analysis." },
            { start: "N-Gram Frequency Analysis:", rest: "The automation visits each top-ranking page to conduct an n-gram frequency analysis, identifying common phrases and terms." },
            { start: "Blog Post Title Generation:", rest: "It suggests various blog post titles based on the analysis. The user selects their preferred title." },
            { start: "Keyword Suggestions:", rest: "The system generates keyword ideas from the n-gram analysis. The user can select suggested keywords or add new ones." },
            { start: "Blog Outline Creation:", rest: "An initial blog outline is generated for the user to review and edit as needed." },
            { start: "Draft Article Generation:", rest: "After reviewing each selection, the automation generates a draft article that aligns with the strategies of top-ranking articles." },
            { start: "WordPress Draft Publishing:", rest: "The user has the option to directly publish the draft article to WordPress as a draft for further editing or immediate publication." }
        ],
        wip: true,
        image: '/images/apps/ShortGenerator_Placeholder.png',
        video : '',
        asset : 'gif',
        url: 'https://seo-ai-writer-demo.robomotion.app/',
        placement: "left",
        logo: "./images/apps/ShortGenerator_SM.png",

    }

];

export default data;

import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from 'react';
import { Container, Center, Button, createStyles } from '@mantine/core';
import Hero from './Hero';
import Users from '../shared/Users';
import Discord from '../shared/Discord';
import Comparison from './Comparison';
import PricingDeveloper from './PricingDeveloper';
import PricingUser from './PricingUser';
import CloudFAQ from './CloudFAQ';
import HostedFAQ from './HostedFAQ';
import colors from '../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin: "auto",
        paddingTop: 20,
        '@media (max-width: 755px)': {
            paddingTop: 20,
            paddingBottom: 20,
        },
    },
    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 20,
    },
}));

const PricingHome = () => {
    const { classes } = useStyles();
    const title = "Pricing - Robomotion RPA";

    // Function to get the 'plan' query parameter value
    const getPlanFromQuery = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const plan = queryParams.get('plan');
        return plan === 'hosted' ? 'Self-host (or we host)' : 'Cloud'; // Default to 'Cloud' if not specified or if the value is not recognized
    };

    const [value, setValue] = useState(getPlanFromQuery());

    // Effect hook to update state if the URL's query parameter changes without page reload
    useEffect(() => {
        const handlePopState = () => {
            setValue(getPlanFromQuery());
        };

        window.addEventListener('popstate', handlePopState);
        return () => window.removeEventListener('popstate', handlePopState);
    }, []);

    // Function to change the URL and update the state
    const updatePlanAndURL = (plan) => {
        const newUrl = `${window.location.pathname}?plan=${plan}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
        setValue(plan === 'hosted' ? 'Self-host (or we host)' : 'Cloud');
    };

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta name="twitter:title" content={title} />
            </Helmet>
            <Hero />
            <Container className={classes.wrapper} size={1200}>
                <div className={classes.hero}>
                    <Container pt={0} size={900}>
                        <Center>
                            <Button
                                styles={{ root: { paddingLeft: 40, paddingRight: 40 } }}
                                size="sm"
                                radius="md"
                                m="sm"
                                onClick={() => updatePlanAndURL('cloud')}
                                variant={value === 'Cloud' ? 'gradient' : 'default'}
                                gradient={{ from: colors.Secondary, to: colors.Secondary }}
                            >
                                Cloud
                            </Button>
                            <Button
                                styles={{ root: { paddingLeft: 40, paddingRight: 40 } }}
                                size="sm"
                                radius="md"
                                m="sm"
                                onClick={() => updatePlanAndURL('hosted')}
                                variant={value === 'Self-host (or we host)' ? 'gradient' : 'default'}
                                gradient={{ from: colors.Secondary, to: colors.Secondary }}
                            >
                                Self-host (or we host)
                            </Button>
                        </Center>
                    </Container>
                </div>
            </Container>
            {value === 'Cloud' && <PricingUser />} 
            {value === 'Cloud' && <CloudFAQ/>}
            {value === 'Cloud' && <Comparison />}
            {value === 'Self-host (or we host)' && <PricingDeveloper />}
            {value === 'Self-host (or we host)' && <HostedFAQ/>}
            <Users />
            <Discord />
        </>
    );
};

export default PricingHome;

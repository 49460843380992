import {
    createStyles,
    Title,
    Text,
    Container,
    Center,
    Mark,
    Stack,
    SimpleGrid,
    Card,
} from '@mantine/core';
import Testimonials from './Testimonials';
import colors from '../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin: "auto",
        paddingTop: 40,

        '@media (max-width: 755px)': {
            paddingTop: 40,
        },
    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 80,
    },

    title: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "3.5em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    subTitle: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "2em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    description: {
        color: colors.Primary,
        textAlign: 'center',
        fontSize: 28,
        fontFamily: 'Barlow',
        
    },

    infoTitle: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: "24px",
        color: colors.Primary,
        fontFamily: 'Inter'
    },

    infoFor: {
        textAlign: 'center',
        fontWeight: 400,
        fontSize: "22px",
        color: colors.Primary,
        fontFamily: 'Inter'
    },

    info: {
        textAlign: 'center',
        fontWeight: 400,
        fontSize: "18px",
        color: colors.Primary,
        fontFamily: 'Inter'
    },


    underlined: {
        textDecoration: "underline wavy #2196F3"
    },


    ratingBase: {
        textAlign: 'center',
        fontSize: 18,
        maxWidth: "300px",
        fontWeight: "400",
        marginTop: "-10px",
        color: colors.PrimaryText,
    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenSM: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

    card: {
        maxWidth: "500px",
        backgroundColor: colors.Transparent,
    },

}));

const Users = () => {

    const { classes } = useStyles();

    return (


            <Container className={classes.wrapper} size={"100%"} >
                <div className={classes.hero}>
                    <Title className={classes.title}>
                        What our <Text component="span"
                            className={classes.highlight}
                            inherit
                            variant="gradient"
                            gradient={{ from: colors.Secondary, to: colors.Secondary }}
                        >
                        {' '}<b>community</b></Text>{' '}tell about <b><u>Robomotion</u></b>.
                    </Title>

                    <Container p={5} size={1200}>
                        <Center>
                            <Text className={classes.description}>
                                More than <b>15,000+</b> users, from <b>next-generation startups</b> to <b>solopreneurs</b> , trust <b>Robomotion</b>.
                            </Text>
                        </Center>
                    </Container>

                    <Container pt={30} pb={30} size={272}>
                        <a target="_blank" rel="noreferrer" href="https://www.capterra.com/reviews/214513/Robomotion?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"> 
                            <img border="0" width="272" height="96" className="h-24 inline" src="https://assets.capterra.com/badge/0cff451917c2b7b22a342f75705830d5.svg?v=2154522&p=214513" alt="Capterra" /> 
                        </a>
                    </Container>
                    <Container p={0} size={1500} className={classes.subDescription}>
                        <Center>
                            <Testimonials />
                        </Center>
                    </Container>
                </div>
            </Container>

    );
}

export default Users;

import { Container, Title, Accordion, createStyles } from '@mantine/core';
import colors from '../../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin:"auto",

    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 80,
    },

    title: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: "3.2em",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        marginBottom: theme.spacing.sm,
        marginTop: theme.spacing.md,
        fontFamily: 'Barlow',

        [theme.fn.smallerThan('lg')]: {
            fontSize: "3em",
        },
    },

    subTitle: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: "1.8em",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        marginBottom: theme.spacing.sm,
        marginTop: theme.spacing.md,
        fontFamily: 'Barlow',

        [theme.fn.smallerThan('lg')]: {
            fontSize: "3em",
        },
    },

    description: {
        color: colors.PrimaryText,
        textAlign: 'center',
        fontSize: 30,
        maxWidth: "1000px",
        lineHeight: 1.7,
        fontFamily: 'Barlow',

    },

    underlined: {
        textDecoration: "underline wavy #2196F3"
    },

    subDescription: {
        marginTop: "40px",
        textAlign: 'center',
        fontSize: 16,
    },

    ratingBase: {
        textAlign: 'center',
        fontSize: 18,
        maxWidth: "300px",
        fontWeight: "400",
        marginTop: "-10px",
        color: colors.PrimaryText,
    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenSM: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },
}));

const faqs = [
        {
            title: 'How much money can I make?',
            description: "There are no caps! You'll receive a 40% commission on every transaction made by your referred customers for their first 12 months of subscription. For instance, referring a single customer to the Pro Plan will earn you $63 monthly. And for the Team Plan, you'll begin earning $175 each month from just one referral.",
            value: '1'
        },
        {
            title: 'What is included for the commisions?',
            description: 'All the plan subscriptions and all the extra Robot and Application subscriptions are included to your commissions.',
            value: '2'
        },
        {
            title: 'How will I get paid?',
            description:  'Payments are made on the first week of every month through wise.com, at least 30 days after the referred consumer switched from a free to a paid account. As a result, on first week of September, you would get payment if your referred customer upgraded their account on July 18.',
            value: '3'
        },
        {
            title: 'What happens if customer changes plan?',
            description: 'As long as the customers do not cancel their plans, you will continue getting your commision by the customers current plan.',
            value: '4'
        },
        {
            title: 'Can I signup with my own referral link?',
            description: 'No. Self referrals are not allowed. The program is for you referring to other people. If we discover a violation your account will be banned and your future commisions will not be paid.',
            value: '5'
        },
        {
            title: 'Is there any marketing restrictions?',
            description: 'Paid ads are allowed but you are not allowed to bid on branded keywords Robomotion.io, Robomotion RPA, Robomotion or impersonate Robomotion as a company. If you do you will be banned from the program and stop re. The best way is to market is to your existing audience in an authentic way.',
            value: '6'
        },
        {
            title: 'I have more questions, who should I ask?',
            description: 'You can reach to us from affiliates@robomotion.io.',
            value: '7'
        }
    ];



const FAQ = () => {
    const { classes } = useStyles();

    const ListQuestions = (x) => {
        return x.map((faq, index) => (
            <Accordion.Item key={index} className={classes.item} value={faq.value}>
                <Accordion.Control>{faq.title}</Accordion.Control>
                <Accordion.Panel>{faq.description}</Accordion.Panel>
            </Accordion.Item>
        ));
    }

    return (
        <Container size="100%" className={classes.wrapper} pt={100} pb={200}>
            <Container size="sm" className={classes.wrapper}>

            <Title align="center" className={classes.title}>
                Frequently Asked Questions
            </Title>

            <Accordion variant="separated">
                {ListQuestions(faqs)}
            </Accordion>
            </Container>
        </Container>
    );
}

export default FAQ;
import {
    createStyles,
    Title,
    Text,
    Container,
    Center,
    Image,
    Card
} from '@mantine/core';
import colors from '../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin: "auto",
        paddingTop: 80,

        '@media (max-width: 755px)': {
            paddingTop: 80,
            paddingBottom: 60,
        },
    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 80,
    },

    title: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "4.5em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    subTitle: {
        textAlign: 'center',
        fontWeight: 300,
        fontSize: "2em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    description: {
        color: colors.Primary,
        textAlign: 'center',
        fontSize: 30,
        maxWidth: "800px",
        fontFamily: 'Barlow',
        
    },

    underlined: {
        textDecoration: "underline wavy #2196F3"
    },

    subDescription: {
        marginTop: "10px",
        paddingBottom: "40px",
        textAlign: 'center',
        fontWeight: 800,
        fontSize: 16,
    },

    image: {
        marginTop: "10px",
        paddingBottom: "40px",
        textAlign: 'center',
    },

    ratingBase: {
        textAlign: 'center',
        fontSize: 18,
        maxWidth: "300px",
        fontWeight: "400",
        marginTop: "-10px",
        color: colors.PrimaryText,
    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenSM: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },


}));

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


const Hero = ({ data }) => {
    const { classes } = useStyles();
    var date = "";

    if (data && data.date) {
        const d = new Date(data.date);
        date = monthNames[d.getMonth()] + " " + ("0" + d.getDate()).slice(-2) + ", " + d.getFullYear();
    }

    return (
        <Container className={classes.wrapper} size={800}>
            <div className={classes.hero}>
                <Title className={classes.title}>
                    {data ? data.title : ""}
                </Title>

                <Container p={0} size={1100}>
                    <Center>
                        <Text className={classes.description}>
                            {data ? data.description : ""}

                        </Text>
                    </Center>
                </Container>
                <Container p={0} size={1100}>
                    <Center>
                        <Text className={classes.subDescription}>
                            {date}
                        </Text>
                    </Center>
                </Container>

                <Container p={0} size={900} className={classes.image}>
                    <Card shadow="xs" m={20} p={50} radius="md" className={classes.imageCard}>
                        <Card p={0} m={0} radius="lg" className={classes.imageCardChild}>
                            <Image fit="contain" className={classes.image} src={data ? "/images/blog/" + data.coverImage : ""} alt="Robomotion Blog"></Image>
                        </Card>
                    </Card>
                </Container>
            </div>
        </Container>

    );
}

export default Hero;

import features from './features';
import { Paper, Table, Group, Container, Text, Title, ScrollArea, createStyles } from '@mantine/core';
import colors from '../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin:"auto",

        '@media (max-width: 755px)': {
        },
    },

    hero: {
        position: 'relative',
        zIndex: 1,
    },

    title: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: "2em",
        lineHeight: "1.2",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        paddingTop: 60,
        paddingBottom: 60,

        [theme.fn.smallerThan('lg')]: {
          fontSize: "1.4em",
      },
    },

    subTitle: {
        color: colors.PrimaryText,
        textAlign: 'center',
        letterSpacing: "-0.02em",
        lineHeight: "1.4",
        fontSize: 48,
        fontWeight: 800,
    },

    description: {
        color: colors.PrimaryText,
        textAlign: 'center',
        letterSpacing: "-0.02em",
        lineHeight: "1.4",
        fontSize: 24,
        marginTop:60,
    },

    subInfo: {
        maxWidth: 410
    },

    subDescription: {
        marginTop: "20px",
        textAlign: 'center',
        fontSize: 16,
    },


    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

}));




const Comparison = () => {
    const { classes } = useStyles();

    const rows = features.map((value, index) => (
        <tr key={index}>
          <td>
            <Group spacing="sm">
              <div>
                <Text size="sm" weight={500}>
                  {value.items[0]}
                </Text>
                <Text size="xs" color="dimmed" className={classes.subInfo}>
                    {value.items[1]}
                </Text>
              </div>
            </Group>
          </td>
    
          <td>
            <Text size="sm" weight={500}>
                    {value.items[2]}
            </Text>
          </td>
          <td>
            <Text size="sm" weight={500}>
                    {value.items[3]}
            </Text>
          </td>
          <td>
            <Text size="sm" weight={500}>
                    {value.items[4]}
            </Text>
          </td>
          <td>
            <Text size="sm" weight={500}>
                    {value.items[5]}
            </Text>
          </td>
        </tr>
      ));

    return (
        <Container className={classes.wrapper} size={1100} mt={40} mb={40}>
            <div className={classes.hero}>
            <Title className={classes.title}>
                Features Comparison
            </Title>
            <Paper shadow="xs" radius="md" withBorder pb="md">
                <ScrollArea>
                    <Table horizontalSpacing="sm" verticalSpacing="sm" fontSize="xl" striped highlightOnHover>
                        <thead>
                        <tr>
                            <th>Features</th>
                            <th>Trial</th>
                            <th>Solo</th>
                            <th>Pro</th>
                            <th>Team</th>
                        </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                    </Table>
                </ScrollArea>
            </Paper>
            </div>
        </Container>
    );
}

export default Comparison;


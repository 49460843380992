
import partners from './data';
import PartnerCard from './PartnerCard';
import colors from '../../../const/colors';

import {
    createStyles,
    Title,
    Text,
    Container,
    SimpleGrid,
    Center
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin:"auto",
        paddingTop: 80,

        '@media (max-width: 755px)': {
            paddingTop: 80,
            paddingBottom: 60,
        },
    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 80,
    },

    title: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: "3.2em",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        marginBottom: theme.spacing.sm,
        marginTop: theme.spacing.md,
        fontFamily: 'Barlow',

        [theme.fn.smallerThan('lg')]: {
            fontSize: "3em",
        },
    },

    subTitle: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: "1.8em",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        marginBottom: theme.spacing.sm,
        marginTop: theme.spacing.md,
        fontFamily: 'Barlow',

        [theme.fn.smallerThan('lg')]: {
            fontSize: "3em",
        },
    },

    description: {
        color: colors.PrimaryText,
        textAlign: 'center',
        fontSize: 30,
        maxWidth: "800px",
        lineHeight: 1.7,
        fontFamily: 'Barlow',

    },

    underlined: {
        textDecoration: "underline wavy #2196F3"
    },

    subDescription: {
        marginTop: "40px",
        textAlign: 'center',
        fontSize: 16,
    },

    ratingBase: {
        textAlign: 'center',
        fontSize: 18,
        maxWidth: "300px",
        fontWeight: "400",
        marginTop: "-10px",
        color: colors.PrimaryText,
    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenSM: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },
}));

const Partners = () => {
    const { classes } = useStyles();

    return (
         <Container className={classes.wrapper} size={"%100"} p={100} m={0} mb={-3}>
            <div className={classes.hero}>
                <Title className={classes.title}>
                    Global  {' '}
                    <Text component="span"
                        className={classes.highlight}
                        inherit
                        variant="gradient"
                        gradient={{ from: colors.Primary, to: colors.Primary }}
                    >
                        Partners&nbsp;
                    </Text>{''}
                    
                </Title>

                <Container px={30} size={900}>
                    <Center>
                        <Text className={classes.subTitle}>
                            Robomotion invests in a trusted network of its partners to provide great consultants and professional RPA developers to its customers.                        
                        </Text>
                    </Center>
                </Container>

                <Container px={30} size={1000}>
                    <Center>
                        <Text className={classes.description}>
                            Work with one of our partners, they are experts in RPA technology and can help you get the most out of your investment. They can help you automate time-consuming tasks, improve your efficiency, and minimize your costs.                        
                        </Text>
                    </Center>
                </Container>

                <Container pt={40} pb={10} size={1100}>
                    <Center>
                    <Text className={classes.subTitle}>
                        <b>Find a Partner</b>
                    </Text>
                    </Center>
                </Container>

                <Container pt={20} pb={20} size={1100}>

                 <Center>
                    <Container className={classes.wrapper} size={1100}>
                        <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'sm', cols: 1 }, { maxWidth: 'md', cols: 2 }]}>
                        { partners.map((p, index) => <PartnerCard key={index} name={p.name} logo={p.logo} url={p.url} /> ) }
                        </SimpleGrid>
                    </Container>
		        </Center>
                </Container>

            </div>
        </Container>
    );
}

export default Partners;

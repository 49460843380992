import {
    createStyles,
    Title,
    Text,
    Container,
    Button
} from '@mantine/core';
import colors from '../../../const/colors.jsx';


const useStyles = createStyles((theme) => ({
    wrapper: {
        margin:"auto",
        paddingTop: 120,
        backgroundColor: colors.PrimaryBackground,

    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 100,
    },

    title: {
        textAlign: 'center',
        fontWeight: 500,
        fontSize: "2.2em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.Primary,
        marginBottom: theme.spacing.xl,
        marginTop: theme.spacing.md,
        fontFamily: 'Barlow',

        [theme.fn.smallerThan('lg')]: {
            fontSize: "1.6em",
        },
    },

    description: {
        color: colors.PrimaryText,
        textAlign: 'center',
        fontSize: 24,
        maxWidth: "670px",
    },

    underlined: {
        textDecoration: "underline wavy #2196F3"
    },

    subDescription: {
        marginTop: "40px",
        textAlign: 'center',
        fontSize: 16,
    },

    ratingBase: {
        textAlign: 'center',
        fontSize: 18,
        maxWidth: "300px",
        fontWeight: "400",
        marginTop: "-10px",
        color: colors.PrimaryText,
    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenSM: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },


}));

const CTA = ({data}) => {
    const { classes } = useStyles();

    return (
        <Container className={classes.wrapper} size={"100%"}>
            <Container  size={1200}>
                <div className={classes.hero}>
                    <Title className={classes.title}>
                        { data ? data.cta: "" } 
                    </Title>
                    <Container p={0} size={900} className={classes.subDescription}>
                        <Text size="lg" >
                            <Button size="lg" radius="md" m="md" px={50} onClick={() => {window.location.href = "https://app.robomotion.io/create"}} variant="gradient" gradient={{ from: colors.Primary, to: colors.Primary }}>
                                TRY ROBOMOTION FOR FREE
                            </Button>
                        </Text>
                        <Text size="lg" p={0} mt={-5}>
                            <div><p>No Credit Card Required. Cancel Anytime.</p></div>
                        </Text>
                    </Container>
                </div>
            </Container>
        </Container>

    );
}

export default CTA;

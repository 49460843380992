const plans = {
  "personal_developer": {
    title: "Personal",
    description: "Start building your personal Assistants",
    icon: "/images/starter-icon.png" ,
    price: "$29",
    pricingType: "per month",
    features: ["1 Development Robot", "3 Application Robots", "50 Flows"],
    cta: "Buy Personal"
  },
  "starter_developer": {
    title: "Starter",
    description: "Public Cloud or On-Premise",
    icon: "/images/starter-icon.png" ,
    price: "$499",
    pricingType: "per month",
    features: ["3 Users", "2 Development Robots", "5 Application Robots", "1 Production Robot", "250 Flows"],
    cta: "Buy Starter"
  },
  "growth_developer": {
    title: "Growth",
    description: "Public Cloud or On-Premise",
    icon: "/images/growth-icon.png" ,
    price: "$999",
    pricingType: "per month",
    features: ["5 Users", "3 Development Robots", "10 Application Robots", "2 Production Robots", "Unlimited Flows", "Triggers & Queues", "Forms"],
    cta: "Buy Growth"
  }, 
  "enterprise_developer": {
    title: "Enterprise",
    description: "Private Cloud or On-Premise with SLAs",
    icon: "/images/enterprise-icon.png" ,
    price: "Let's Talk",
    pricingType: "-",
    features: ["Unlimited Robots", "Unlimited Flows", "Unlimited Users", "SLA & Support"],
    cta: "Contact Us"
  },
  "free_user": {
    title: "Trial",
    description: "For Starting Your Automation Journey",
    icon: "/images/starter-icon.png" ,
    price: "$0",
    pricingType: "-",
    features: [
      "1 User", 
      "1 Development Robot", 
      "1 Application Robot",      
      "3 Flows",       
      "Unlimited Local Execution Time",
      "4h Cloud Execution Time", 
      "5k AI Credits", 
      "1k Proxy Requests", 
      "1 GB Storage"
    ],       
    cta: "Try 14-Day Free"
  },
  "solo_user": {
    title: "Solo",
    description: "For Solopreneurs to Boost Productivity",
    icon: "/images/starter-icon.png" ,
    price: "$69",
    pricingType: "per month",
    features: [
      "1 User", 
      "1 Development Robot", 
      "3 Application Robot",
      "50 Flows", 
      "Unlimited Local Execution Time", 
      "80h / month Cloud Execution Time", 
      "25k / month AI Credits", 
      "50k / month Proxy Requests", 
      "25 GB Storage"
    ],       
    cta: "Buy Solo"
  },
  "pro_user": {
    title: "Pro",
    description: "For Early Stage Startups",
    icon: "/images/growth-icon.png" ,
    price: "$159",
    pricingType: "per month",
    features: [
      "3+ User", 
      "3 Development Robot", 
      "1 Production Robot",
      "5 Application Robot",
      "250 Flows", 
      "Unlimited Local Execution Time", 
      "240h / month Cloud Execution Time", 
      "75k / month AI Credits", 
      "150k / month Proxy Requests", 
      "250 GB Storage"
    ],    
    cta: "Buy Pro"
  }, 
  "team_user": {
    title: "Team",
    description: "From Small to Medium Businesses",
    icon: "/images/enterprise-icon.png" ,
    price: "$499",
    pricingType: "per month",
    features: [
      "5+ User", 
      "5 Development Robot", 
      "2 Production Robot",
      "15 Application Robot",
      "Unlimited Flows", 
      "Unlimited Local Execution Time", 
      "720h / month Cloud Execution Time", 
      "250k / month AI Credits", 
      "500k / month Proxy Requests", 
      "1 TB Storage"
    ],
    cta: "Buy Team"
  }
};

export default plans;

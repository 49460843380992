import BlogCard from './Card';

import {
    Container,
    createStyles,
    SimpleGrid,
} from '@mantine/core';
import colors from '../../const/colors';

function splitter(str, l) {
    if (!str) return [];
    var strs = [];
    while (str.length > l) {
        var pos = str.substring(0, l).lastIndexOf(' ');
        pos = pos <= 0 ? l : pos;
        strs.push(str.substring(0, pos));
        var i = str.indexOf(' ', pos) + 1;
        if (i < pos || i > pos + l)
            i = pos;
        str = str.substring(i);
    }
    strs.push(str);
    return strs;
}

function format(date) {
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const d = new Date(date);
    return monthNames[d.getMonth()] + " " + ("0" + d.getDate()).slice(-2) + ", " + d.getFullYear();
}

const useStyles = createStyles((theme) => ({
    wrapper: {
        position: 'relative',
        margin: "auto",
        display: 'flex',
        paddingTop: 80,

        '@media (max-width: 755px)': {
            paddingTop: 80,
        },
    },

    hero: {
        position: 'relative',
        zIndex: 1,
        alignContent: "center",
        textAlign: "center"
    },

    title: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "4.5em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    subTitle: {
        textAlign: 'center',
        fontWeight: 300,
        fontSize: "2em",
        lineHeight: "1.25",
        letterSpacing: "-0.02em",
        color: colors.PrimaryText,
        fontFamily: 'Barlow',
    },

    description: {
        color: colors.Primary,
        textAlign: 'center',
        fontSize: 30,
        maxWidth: "800px",
        fontFamily: 'Barlow',
        
    },

    subDescription: {
        marginTop: "80px",
        textAlign: 'center',
        fontSize: 16,
    },


    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

}));


const Posts = ({ data }) => {
    const { classes } = useStyles();

    return (
        <>
            <Container className={classes.wrapper} size={1200}>
                <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }, { maxWidth: 'lg', cols: 2 }]}>
                    {data.map((blog, index) => (
                        <BlogCard key={index} url={blog.Url} title={blog.Title} cover={blog.CoverImage}
                            date={format(blog.Date)}
                            description={splitter(blog.Description, 148)[0]} />
                    ))}
                </SimpleGrid>
            </Container>
        </>
    );
}

export default Posts;
